import { FC, ReactNode, useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { Icon } from 'src/assets/icons'

import { RegularText } from '../RegularText'

import styles from './styles.module.scss'

interface IProps {
    onClose: () => void
    isOpen: boolean
    children: ReactNode
    title: string
}

export const NormalModal: FC<IProps> = ({ onClose, isOpen, children, title }): JSX.Element => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isOpen])

    return (
        <>
            {createPortal(
                <div
                    onClick={onClose}
                    id='overlay'
                    className={classNames({
                        [styles.modal]: true,
                        [styles.show]: isOpen,
                    })}>
                    <div
                        onClick={event => event.stopPropagation()}
                        className={classNames(styles.modalContent, 'rounded-lg shadow-lg flex flex-col')}>
                        <div className={styles.header}>
                            <RegularText className='!text-white'>{title}</RegularText>
                            <Icon type='close' className='cursor-pointer' onClick={onClose} />
                        </div>
                        {children}
                    </div>
                </div>,
                document.getElementById('modal') as HTMLElement,
            )}
        </>
    )
}
