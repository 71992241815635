import { FC, useEffect, useState } from 'react'
import styles from '../../pages/ReportPage/components/ModalReport/styles.module.scss'
import { Button, MediumItalic, RegularText, SemiBoldText } from '../../components'
import { useSetAtom } from 'jotai/index'
import { setCloseSubModalAtom, setModalCloseAtom } from '../../atoms/global/modalAtom'
import { api } from '../../axios'
import { Order } from '../../services'

export const StaticOrderModal: FC<{ isSubModal: boolean; currentOrder: Order | null }> = ({ isSubModal, currentOrder }) => {
    const closeModal = useSetAtom(setModalCloseAtom)
    const closeSubModal = useSetAtom(setCloseSubModalAtom)

    const onBackClick = () => {
        if (isSubModal) {
            closeSubModal()
        } else {
            closeModal()
        }
    }

    return (
        <>
            <div className='flex justify-center gap-1' style={{ margin: '12px 0 12px 0' }}>
                <SemiBoldText>Общее время заказа</SemiBoldText> <MediumItalic>{currentOrder?.orderTimer || '-'}</MediumItalic>
            </div>
            <div className={styles.container}>
                <div className={styles.card}>
                    <MediumItalic>Ресторан</MediumItalic>
                    <ul className={styles.statusList}>
                        <li className={styles.statusItem}>
                            <span>Новый заказ - Заказ принят</span>
                            <span>{currentOrder?.orderNewElapsed || '-'}</span>
                        </li>
                        <li className={styles.statusItem}>
                            <span>Заказ принят - Заказ готов</span>
                            <span>{currentOrder?.orderCookingElapsed || '-'}</span>
                        </li>
                        <li className={styles.statusItem}>
                            <span>Заказ готов - Заказ отправлен</span>
                            <span>{currentOrder?.orderCookedElapsed || '-'}</span>
                        </li>
                        <li className={styles.statusItem}>
                            <span>Заказ отправлен - Заказ доставлен</span>
                            <span>{currentOrder?.orderShippingElapsed || '-'}</span>
                        </li>
                    </ul>
                </div>

                <div className={styles.card}>
                    <MediumItalic>Курьер</MediumItalic>
                    <ul className={styles.statusList}>
                        <li className={styles.statusItem}>
                            <span>Новый заказ - Заказ принят</span>
                            <span>{currentOrder?.courierOrderNewElapsed || '-'}</span>
                        </li>
                        <li className={styles.statusItem}>
                            <span>Заказ принят - Заказ у курьера</span>
                            <span>{currentOrder?.courierOrderGoingElapsed || '-'}</span>
                        </li>
                        <li className={styles.statusItem}>
                            <span>Заказ у курьера - Заказ доставлен</span>
                            <span>{currentOrder?.courierOrderShippingElapsed || '-'}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginRight: '20px' }}>
                <Button className='w-36' onClick={onBackClick}>
                    <RegularText>Выйти</RegularText>
                </Button>
            </div>
        </>
    )
}
