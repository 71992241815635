import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { AppSelect, ESelectVariant, SelectOption, SemiBoldText } from '../../components'
import { ReportRest } from './components/ReportRest'
import { ReportCourer } from './components/ReportCourer'
import { useAtomValue, useSetAtom } from 'jotai/index'
import { getRestAtom, restsAtom } from '../../atoms/restaraunts/restAtom'
import { PropsValue } from 'react-select'
import { AppCitySelect } from '../../components/AppCitySelect'
import { api } from '../../axios'

export type DateType = {
    dateFrom: string
    dateTo: string
}

export interface IReport {
    title: string
    stats: {
        type: string
        stages: {
            new: number
            cooking: number
            completepickup: number
            cooked: number
            going: number
            shipping: number
            complete: number
            orderTime: number
        }
    }[]
}

export const ReportPage: FC = () => {
    const [tab, setTab] = useState(1)
    const [dates, setDates] = useState<DateType>({
        dateFrom: '',
        dateTo: '',
    })
    const [loading, setLoading] = useState<boolean>(false)
    const restaurants = useAtomValue(restsAtom)
    const [selectedCity, setSelectedCity] = useState<SelectOption>()
    const [selectedRest, setSelectedRest] = useState<SelectOption>()
    const [data, setData] = useState<IReport[]>([])
    const getRests = useAtomValue(getRestAtom)
    const setRests = useSetAtom(restsAtom)

    useEffect(() => {
        if (getRests?.data) {
            setRests(getRests.data)
        }
    }, [getRests])

    const handleSelectCity = (val?: PropsValue<SelectOption>) => {
        const value = val as SelectOption
        setSelectedCity(value)
    }

    const setDate = (fieldName: 'dateFrom' | 'dateTo') => (val: string) => {
        setDates(prev => ({ ...prev, [fieldName]: val }))
    }

    const handleSelectRest = (val: PropsValue<SelectOption>) => {
        const value = val as SelectOption
        setSelectedRest(value)
    }

    const getData = async () => {
        try {
            setLoading(true)
            const { data } = await api.get('/admin/time/stats', {
                params: {
                    city: selectedCity?.value,
                    rest_id: selectedRest?.value,
                    dateFrom: dates?.dateFrom,
                    dateTo: dates?.dateTo,
                    courier: tab === 2 ? true : null,
                },
            })
            setData(data)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [selectedCity?.value, tab, selectedRest?.value, dates?.dateFrom, dates?.dateTo])

    return (
        <>
            <div className={styles.container}>
                <div className={classNames('flex items-center h-full', styles.header)}>
                    <div className='flex gap-4'>
                        <SemiBoldText className='text-2xl mr-4 ml-6'>Отчеты</SemiBoldText>

                        <div className={styles.tabs}>
                            <div
                                className={tab === 1 ? styles.activeTab : styles.tab}
                                onClick={() => {
                                    setTab(1)
                                    setDates({ dateFrom: '', dateTo: '' })
                                }}>
                                Ресторан
                            </div>
                            <div
                                className={tab === 2 ? styles.activeTab : styles.tab}
                                onClick={() => {
                                    setTab(2)
                                    setDates({ dateFrom: '', dateTo: '' })
                                    setSelectedRest(undefined)
                                }}>
                                Курьеры
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', gap: 5 }}>
                        <AppCitySelect
                            selectVariant={ESelectVariant.QUARTERY}
                            placeholder={'Все города'}
                            isClearable
                            onChange={handleSelectCity}
                            value={selectedCity}
                        />
                        {tab === 1 && (
                            <AppSelect
                                value={selectedRest}
                                onChange={handleSelectRest}
                                options={
                                    restaurants.map(item => ({ value: item.rest_id, label: item.restName }))
                                    // restaurants?.length
                                    //     ? [
                                    //           { value: true, label: 'Независимые заказы' },
                                    //           ...restaurants.map(item => ({ value: item.rest_id, label: item.restName })),
                                    //       ]
                                    //     : []
                                }
                                selectVariant={ESelectVariant.QUARTERY}
                                placeholder={'Все рестораны'}
                                isClearable
                            />
                        )}
                    </div>
                </div>
            </div>
            <div style={{ margin: '20px 20px 0 20px' }}>
                {tab === 1 && <ReportRest loading={loading} data={data} setDate={setDate} dates={dates} />}
            </div>
            <div style={{ margin: '20px 20px 0 20px' }}>
                {tab === 2 && <ReportCourer loading={loading} data={data} setDate={setDate} dates={dates} />}
            </div>
        </>
    )
}
