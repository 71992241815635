import { atom } from 'jotai'
import { CreateRestRequest, LokaliApi, RestWorkSchedule } from 'src/services'
import { HttpStatusCode } from 'src/types'
import { WORK_DAYS, WorkDay } from 'src/features/Restaraunts/utils'
import { atomWithImmer } from 'jotai-immer'
import { LatLng } from 'leaflet'
import { CreateRestFormData } from 'src/features/Restaraunts/CreateRestSchema'

import { setModalCloseAtom } from '../global/modalAtom'

import { getRestAtom } from './restAtom'
import { editCurrentDeliveryZoneAtom } from './editRestDeliveryZoneAtom'

type DeliveryZone = {
    title: string
    description: string
    latLng: LatLng[]
}

const createAllDaysAtom = atomWithImmer<WorkDay>({
    workStart: '',
    workEnd: '',
    lunchStart: '',
    lunchEnd: '',
    day: 'ВСЕ',
    isActive: false,
})

const createWorkTimeAtom = atomWithImmer<WorkDay[]>(WORK_DAYS)
const currentWorkTimeModeAtom = atom<boolean>(true)
const currentWorkTimeIndexAtom = atom<number>(-1)

const createRestNetworkStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const createRestMapMarkersAtom = atom<LatLng[]>([])
const createRestDeliveryZonesAtom = atom<DeliveryZone[]>([])

const createCurrentDeliveryZoneAtom = atom<DeliveryZone | undefined>(undefined)

const createSelectDeliveryZoneAtom = atom(null, (_, set, update: DeliveryZone) => {
    set(createCurrentDeliveryZoneAtom, update)
})

const currentDeliveryZoneIndexAtom = atom<number>(-1)

const addRestDeliveryZoneAtom = atom(null, (get, set, update: number) => {
    set(createRestDeliveryZonesAtom, old => [
        ...old,
        {
            title: `Зона доставки ${update}`,
            description: 'Описание',
            latLng: get(createRestMapMarkersAtom),
        },
    ])
    set(createRestMapMarkersAtom, [])
})

const createRestAtom = atom(null, async (get, set, update: CreateRestFormData) => {
    const workTime = get(createWorkTimeAtom)
    const mode = get(currentWorkTimeModeAtom)
    const deliveryZone = get(createRestDeliveryZonesAtom)

    const allDays = get(createAllDaysAtom)

    const eachDay: RestWorkSchedule[] = workTime.map((time, idx) => ({
        day: (idx + 1).toString(),
        from: time.workStart,
        to: time.workEnd,
        breakFrom: time.lunchStart,
        breakTo: time.lunchEnd,
    }))

    const all: RestWorkSchedule[] = ['1', '2', '3', '4', '5', '6', '7'].map(item => ({
        day: item,
        from: allDays.workStart,
        to: allDays.workEnd,
        breakFrom: allDays.lunchStart,
        breakTo: allDays.lunchEnd,
    }))

    set(createRestNetworkStateAtom, HttpStatusCode.LOADING)

    try {
        const req: CreateRestRequest = {
            ...update,
            servType: update.servType ? 'iiko' : 'server',
            group_id: update.group_id,
            paymentMethods: update.paymentMethods,
            schedule: mode ? all : eachDay,
            isOurCouriers: update.isOurCouriers === 'Lokali',
            order_region: deliveryZone.map(zone => zone.latLng) as unknown as number[][],
        }
        console.log(req)
        const response = await LokaliApi.createRest(req)

        if (response === HttpStatusCode.OK) {
            set(createRestNetworkStateAtom, HttpStatusCode.OK)
            set(setModalCloseAtom)
            set(getRestAtom)
        }
        set(createRestNetworkStateAtom, HttpStatusCode.I_AM_A_TEAPOT)
    } catch (e) {}
})

export {
    createRestAtom,
    createWorkTimeAtom,
    currentWorkTimeModeAtom,
    currentWorkTimeIndexAtom,
    createAllDaysAtom,
    createRestMapMarkersAtom,
    createRestDeliveryZonesAtom,
    addRestDeliveryZoneAtom,
    currentDeliveryZoneIndexAtom,
    createSelectDeliveryZoneAtom,
    createCurrentDeliveryZoneAtom,
}

export type { DeliveryZone }
