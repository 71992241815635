import React, { FC, useEffect, useState } from 'react'
import { Tabs } from '../../components'
import { InfoOrderModal } from './InfoOrderModal'
import { StaticOrderModal } from './StaticOrderModal'
import { Order } from '../../services'
import { api } from '../../axios'
import { useAtomValue } from 'jotai/index'
import { currentOrderAtom } from '../../atoms/allOrders/ordersAtom'

const ModalOrderInfo: FC<{ isSubModal?: boolean }> = (props): JSX.Element => {
    const order = useAtomValue(currentOrderAtom)
    const [currentOrder, setCurrentOrder] = useState<Order | null>(null)

    const getOrder = async () => {
        const { data } = await api.get('/admin/admin-single', { params: { order_id: order?.order_id } })
        setCurrentOrder(data)
    }
    useEffect(() => {
        getOrder()
    }, [])

    return (
        <>
            <Tabs
                tabs={[
                    {
                        tabOption: {
                            tabName: 'Инфо',
                        },
                        child: <InfoOrderModal currentOrder={currentOrder} {...props} />,
                    },
                    {
                        tabOption: {
                            tabName: 'Статистика',
                        },
                        child: <StaticOrderModal currentOrder={currentOrder} isSubModal={props.isSubModal as boolean} />,
                    },
                ]}
            />
        </>
    )
}

export { ModalOrderInfo }
