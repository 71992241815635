import classNames from 'classnames'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useMemo } from 'react'
import { setEditRestModal } from 'src/atoms/restaraunts/restModalsAtom'
import { GridTable, GridTableHead, GridTableItem } from 'src/components'
import { tableActiveClassName } from 'src/utils'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'

import { currentRestAtom, getRestAtom } from '../../atoms/restaraunts/restAtom'
import { Rest } from '../../services'
import { getAddressFromObject } from '../../utils/getAddressFromObject'

import styles from './styles.module.scss'

const THEAD = [
    'Таб. номер',
    'Название',
    'Телефон',
    'Управляющий (ФИО)',
    'Адрес',
    'Время начала работы',
    'Время конца работы',
    'Статус работы ( Открыт/Закрыт )',
    'Телеграм',
    'Сайт',
    'Типы оплат',
    'Статус блокировки',
]

const getTodaySchedule = (list: Rest['schedule']): Rest['schedule'][0] | undefined => {
    const today = moment().day()
    const schedule = list.find(item => +item.day === today)
    return schedule
}

export const RestTable = (): JSX.Element => {
    const editRest = useSetAtom(setEditRestModal)
    const [restState, setRest] = useAtom(currentRestAtom)
    const { data } = useAtomValue(getRestAtom)
    const [searchParams, setSearchParams] = useSearchParams()

    const theads = useMemo(
        () => THEAD.map(el => <GridTableHead key={el} className='bg-aqua-400 p-3 text-nowrap whitespace-nowrap' title={el} />),
        [],
    )

    useEffect(() => {
        if (!data?.length) return

        const restId = window.location.search?.split('rest-id=')[1]
        if (!restId) return

        const rest = data.find(item => item.rest_id === restId)
        if (!rest) return

        setRest(rest)
        editRest()
        setSearchParams(params => {
            params.set('rest-id', '')
            return params
        })
    }, [data])

    return (
        <GridTable theads={theads} className={styles.table}>
            {data &&
                data.map(rest => (
                    <tr key={rest.rest_id}>
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={rest.tab_number}
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={rest.restName}
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={rest.phoneNumber}
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={rest.manager}
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={getAddressFromObject(rest.address)}
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={getTodaySchedule(rest.schedule)?.from || '-'}
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={getTodaySchedule(rest.schedule)?.to || '-'}
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={rest.isOpen ? 'Открыт' : 'Закрыт'}
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={rest.telegram}
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={rest.website}
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={
                                rest.paymentMethods
                                    ? rest.paymentMethods.map(item => (item === 'cash' ? 'Наличные' : 'Картой')).join(', ')
                                    : ''
                            }
                        />
                        <GridTableItem
                            onClick={() => setRest(rest)}
                            onDoubleClick={editRest}
                            className={classNames(
                                'p-2 text-nowrap whitespace-nowrap',
                                tableActiveClassName(rest.rest_id === restState?.rest_id),
                            )}
                            child={rest.is_blocked ? 'Заблокирован' : 'Не заблокирован'}
                        />
                        {/*{TBODY.map(key => {*/}
                        {/*    let node*/}

                        {/*    node = rest[key] || '-'*/}

                        {/*    if (key === 'address') {*/}
                        {/*        node = getAddressFromObject(rest.address)*/}
                        {/*    } else if (key === 'paymentMethods') {*/}
                        {/*        node = rest.paymentMethods?.length*/}
                        {/*            ? rest.paymentMethods.map(item => (item === 'cash' ? 'Наличные' : 'Картой')).join(', ')*/}
                        {/*            : ''*/}
                        {/*    } else if (key === 'is_blocked') {*/}
                        {/*        node = rest.is_blocked ? 'Заблокирован' : 'Не заблокирован'*/}
                        {/*    } else if (key === 'scheduleStart') {*/}
                        {/*        node = getTodaySchedule(rest.schedule)?.from || '-'*/}
                        {/*    } else if (key === 'scheduleEnd') {*/}
                        {/*        node = getTodaySchedule(rest.schedule)?.to || '-'*/}
                        {/*    } else if (key === 'isOpen') {*/}
                        {/*        node = rest.isOpen ? 'Открыт' : 'Закрыт'*/}
                        {/*    }*/}

                        {/*    return (*/}
                        {/*        <GridTableItem*/}
                        {/*            onClick={() => setRest(rest)}*/}
                        {/*            onDoubleClick={editRest}*/}
                        {/*            className={classNames(*/}
                        {/*                'p-2 text-nowrap whitespace-nowrap',*/}
                        {/*                tableActiveClassName(rest.rest_id === restState?.rest_id),*/}
                        {/*            )}*/}
                        {/*            key={key}*/}
                        {/*            child={typeof node === 'string' && node}*/}
                        {/*        />*/}
                        {/*    )*/}
                        {/*})}*/}
                    </tr>
                ))}
        </GridTable>
    )
}
