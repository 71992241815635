import { atomWithRefresh } from 'jotai/utils'
import { ClientOrder, LokaliApi } from 'src/services/LocaliRepository'
import { ClientDB } from 'src/services/LocaliRepository/LocaliRepositorySchemas/ClientDBSchema'
import { DefaultRequestState, HttpStatusCode } from 'src/types'
import { atom } from 'jotai'
import { CancelClientOrderRequest, DeleteClientOrderRequest } from 'src/services/LocaliRepository/types/ClientDB'
import { DeleteClientOrderDialog } from 'src/features/ClientDatabaseModals/DeleteClientOrderDialog'
import { CancelClientOrderDialog } from 'src/features/ClientDatabaseModals/CancelClientOrderDialog'

import { ModalDeleteClient } from '../../features/ClientDatabaseModals/ModalDeleteClient'
import { MassBonusModal } from '../../features/ClientDatabaseModals/MassBonusModal'
import { Icon } from '../../assets/icons'
import { ChangeClientGroupModal } from '../../features/ClientDatabaseModals/ChangeClientGroupModal'
import { ModalOrderInfo } from '../../features/Orders'

import { dialogStateAtom } from '../global/dialogAtom'
import { subModalAtom } from '../global/modalAtom'

import { currentClientGroupAtom } from './clientGroupsAtom'

const currentClientAtom = atom<ClientDB | null>(null)

const doubleClickedClientAtom = atom<ClientDB | null>(null)

const setCurrentClientAtom = atom(null, (_, set, update: ClientDB | null) => {
    set(currentClientAtom, update)
})

const clientDbCopiedTabNumberAtom = atom<boolean>(false)

const currentClientBonusesAtom = atom(async get => {
    const { data } = await get(dbClientsAtom)
    const currentClientId = get(currentClientAtom)?.clientData.client_id
    if (data) {
        return data.find(el => el.clientData.client_id === currentClientId)?.clientData.bonuscount
    }

    return null
})

const showBannedClientsAtom = atom<boolean>(false)

const setShowBannedClientsAtom = atom(null, async (get, set, data: boolean) => {
    set(showBannedClientsAtom, data)
    get(dbClientsAtom).then()
})

const cityFilterClientsAtom = atom<string>('')

const setCityFilterClientsAtom = atom(null, async (get, set, data: string) => {
    set(cityFilterClientsAtom, data)
    get(dbClientsAtom).then()
})

const getClientsOrdersAtom = atomWithRefresh(async get => {
    const clientId = get(currentClientAtom)?.clientData.client_id

    if (!clientId) {
        return {
            data: [],
            error: null,
        }
    }
    try {
        const data = await LokaliApi.getClientOrders({
            clientId,
        })
        return {
            data: data,
            error: null,
        }
    } catch (e) {
        return {
            data: [],
            error: e as Error,
        }
    }
})

const CompleteStatuses = ['complete', 'cancelled']

const activeClientOrdersAtom = atom(async get => {
    const orders = await get(getClientsOrdersAtom)

    if (orders.data) {
        return orders.data.filter(order => !CompleteStatuses.includes(order.status))
    }

    return []
})

const finishedClientOrdersAtom = atom(async get => {
    const orders = await get(getClientsOrdersAtom)

    if (orders.data) {
        return orders.data.filter(order => CompleteStatuses.includes(order.status))
    }

    return []
})

const refreshDbClientsAtom = atom(null, (_, set) => {
    set(dbClientsAtom)
})

const clientDbSearchParamAtom = atom('')

const clientsAtom = atom<ClientDB[]>([])

const clientListPaginationAtom = atom({
    current: 1,
    pageSize: 10,
})

const dbClientsAtom = atomWithRefresh<Promise<DefaultRequestState<ClientDB[]>>>(async get => {
    const currentGroup = get(currentClientGroupAtom)
    const searchParam = get(clientDbSearchParamAtom)
    const showBanned = get(showBannedClientsAtom)
    const cityFilter = get(cityFilterClientsAtom)
    const { pageSize, current } = get(clientListPaginationAtom)
    const city = cityFilter.includes(',') ? cityFilter.split(',')[0] : cityFilter

    try {
        const { data, total, totalPages } = await LokaliApi.getClientsDB({
            group_id: currentGroup?.clientgroup_id || '',
            search: searchParam,
            showBanned,
            city,
            limit: pageSize,
            page: current,
            admin: true,
        })
        return {
            data: data,
            error: null,
            total,
            totalPages,
        }
    } catch (e) {
        return {
            data: [],
            error: e as Error,
        }
    }
})

const cancelClientOrderRequestStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const cancelClientOrderAtom = atom(null, async (_, set, req: CancelClientOrderRequest) => {
    try {
        const response = await LokaliApi.cancelClientOrder(req)

        if (response === HttpStatusCode.OK) {
            set(cancelClientOrderRequestStateAtom, HttpStatusCode.OK)
        }
    } catch (e) {
        set(cancelClientOrderRequestStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

const deleteClientOrderRequestStateAtom = atom<HttpStatusCode>(HttpStatusCode.I_AM_A_TEAPOT)

const deleteClientOrderAtom = atom(null, async (_, set, req: DeleteClientOrderRequest) => {
    try {
        const response = await LokaliApi.deleteClientOrder(req)

        if (response === HttpStatusCode.OK) {
            set(deleteClientOrderRequestStateAtom, HttpStatusCode.OK)
        }
    } catch (e) {
        set(deleteClientOrderRequestStateAtom, HttpStatusCode.INTERNAL_SERVER_ERROR)
    }
})

const openDeleteClientOrderDialogAtom = atom(null, (_, set) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <DeleteClientOrderDialog />,
    })
})

const openCancelClientOrderDialogAtom = atom(null, (_, set) => {
    set(dialogStateAtom, {
        isOpen: true,
        children: <CancelClientOrderDialog />,
    })
})

const openClientOrderDetailsAtom = atom(null, (_, set, data: ClientOrder) => {
    set(subModalAtom, {
        isOpen: true,
        children: <ModalOrderInfo isSubModal />,
        title: `Заказ №${data.orderNumber}`,
        width: 1200,
        height: 700,
    })
})

const openClientDeleteModalAtom = atom(null, (_, set, data: ClientDB | null) => {
    if (!data?.clientData?.client_id) {
        return
    }
    set(dialogStateAtom, {
        isOpen: true,
        children: <ModalDeleteClient client={data} />,
    })
})

export const openChangeClientGroupAtom = atom(null, (_, set, data: ClientDB | null) => {
    if (!data?.clientData?.client_id) {
        return
    }
    set(subModalAtom, {
        isOpen: true,
        children: <ChangeClientGroupModal />,
        title: 'Переместить в группу',
        icon: <Icon type='moveWhite' width={20} height={20} />,
        height: 700,
    })
})

export {
    dbClientsAtom,
    currentClientAtom,
    clientsAtom,
    clientListPaginationAtom,
    setCurrentClientAtom,
    currentClientBonusesAtom,
    clientDbCopiedTabNumberAtom,
    getClientsOrdersAtom,
    activeClientOrdersAtom,
    finishedClientOrdersAtom,
    cancelClientOrderAtom,
    cancelClientOrderRequestStateAtom,
    deleteClientOrderRequestStateAtom,
    deleteClientOrderAtom,
    openCancelClientOrderDialogAtom,
    openDeleteClientOrderDialogAtom,
    openClientOrderDetailsAtom,
    refreshDbClientsAtom,
    clientDbSearchParamAtom,
    openClientDeleteModalAtom,
    doubleClickedClientAtom,
    showBannedClientsAtom,
    setShowBannedClientsAtom,
    cityFilterClientsAtom,
    setCityFilterClientsAtom,
}
