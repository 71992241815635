import { useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { Icon } from 'src/assets/icons'
import { useAtomValue, useSetAtom } from 'jotai'
import { modalAtom, setModalCloseAtom } from 'src/atoms/global/modalAtom'

import { RegularText } from '../RegularText'

import styles from './styles.module.scss'

export const AppModal = (): JSX.Element => {
    const { isOpen, title, children, onClose } = useAtomValue(modalAtom)
    const setCloseModal = useSetAtom(setModalCloseAtom)

    const handleEsc = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape' && !onClose && isOpen) {
                setCloseModal()
            }
            if (e.key === 'Escape' && onClose && isOpen) {
                onClose()
            }
        },
        [setCloseModal, onClose, isOpen],
    )

    const onClickOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
        const node = event.target as HTMLDivElement
        if (node.id === 'overlay' && !onClose) {
            setCloseModal()
        }

        if (node.id === 'overlay' && onClose) {
            onClose()
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'
            document.addEventListener('keyup', handleEsc)
        } else {
            document.body.style.overflow = 'auto'
            document.removeEventListener('keyup', handleEsc)
        }
    }, [handleEsc, isOpen])

    return (
        <>
            {createPortal(
                <div
                    onClick={onClickOverlay}
                    id='overlay'
                    className={classNames({
                        [styles.modal]: true,
                        [styles.show]: isOpen,
                    })}>
                    <div className={classNames(styles.modalContent, 'rounded-lg shadow-lg flex flex-col')}>
                        <div className={styles.header}>
                            <RegularText className='!text-white'>{title}</RegularText>
                            <Icon type='close' className='cursor-pointer' onClick={onClose ? onClose : setCloseModal} />
                        </div>
                        {children}
                    </div>
                </div>,
                document.getElementById('modal') as HTMLElement,
            )}
        </>
    )
}
