import { useAtomValue, useSetAtom } from 'jotai'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { selectedCourierGroupAtom } from 'src/atoms/allCouriers/allCouriersAtom'
import { openDeleteCourierGroupDialogAtom } from 'src/atoms/allCouriers/deleteCourierGroupAtom'
import { editCourierGroupAtom } from 'src/atoms/allCouriers/editCourierGroupAtom'
import { setModalCloseAtom } from 'src/atoms/global/modalAtom'
import { AppImage, Button, InputWrapper, MediumItalic, RegularText, TextArea, TextInput } from 'src/components'
import { EButtonVariant } from 'src/components/Button'
import { EditCourierGroupRequest } from 'src/services'
import { IShippingCostInput } from './AddCourierGroup'
import { Icon } from '../../assets/icons'

export const EditCourierGroup = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const currentCourierGroup = useAtomValue(selectedCourierGroupAtom)
    const openDeleteCourierGroupDialog = useSetAtom(openDeleteCourierGroupDialogAtom)
    const closeModal = useSetAtom(setModalCloseAtom)
    const editCourierGroup = useSetAtom(editCourierGroupAtom)
    const [formState, setFormState] = useState<{
        range?: number | null
        mass?: number | null
        cargo_size?: number | null
        comment?: string | null
        title?: string
        group_id?: string
    }>({
        comment: '',
        range: null,
        cargo_size: null,
        mass: null,
        title: '',
    })
    const [shipCostInputs, setShipCostInputs] = useState<IShippingCostInput[]>(() => [])
    const [isFormValid, setIsFormValid] = useState(true)

    const onChangeField = (name: keyof EditCourierGroupRequest, val: string) => {
        setFormState(old => ({ ...old, [name]: val }))
    }

    useEffect(() => {
        if (currentCourierGroup) {
            setFormState({
                comment: currentCourierGroup.item.comment || '',
                range: currentCourierGroup.item.range || null,
                mass: currentCourierGroup.item.mass || null,
                cargo_size: currentCourierGroup.item.cargo_size || null,
                title: currentCourierGroup.item.title || '',
            })
            if (Array.isArray(currentCourierGroup.item.independent)) {
                setShipCostInputs(
                    currentCourierGroup.item.independent.map(item => ({
                        id: Date.now() + Math.random().toString(),
                        from: item.distance_from,
                        to: item.distance_to,
                        price: item.price,
                    })),
                )
            }
        }
    }, [currentCourierGroup])

    const onClickEdit = async () => {
        if ((formState.title || '').trim() === '') {
            setIsFormValid(false)
            return
        }
        const independent = shipCostInputs.map(item => ({
            distance_from: item.from,
            distance_to: item.to,
            price: Number(item.price) || 0,
        }))

        setIsFormValid(true)
        setLoading(true)
        await editCourierGroup({
            independent,
            range: formState.range,
            mass: formState.mass,
            cargo_size: formState.cargo_size,
            comment: formState.comment,
            group_id: currentCourierGroup?.item.group_id,
            title: formState.title,
        })
        setLoading(false)
    }

    const handleReset = () => {
        if (currentCourierGroup) {
            setFormState({
                comment: currentCourierGroup.item.comment || '',
                range: currentCourierGroup.item.range || null,
                mass: currentCourierGroup.item.mass || null,
                cargo_size: currentCourierGroup.item.cargo_size || null,
                title: currentCourierGroup.item.title || '',
            })
        }
    }

    const addInputs = () => {
        const newItem: IShippingCostInput = {
            id: Date.now() + Math.random().toString(),
            from: '',
            to: '',
            price: undefined,
        }

        setShipCostInputs([...shipCostInputs, newItem])
    }

    const deleteInputs = (id: string) => {
        const filterItem: IShippingCostInput[] = shipCostInputs.filter((item: IShippingCostInput) => item.id !== id)
        setShipCostInputs(filterItem)
    }

    const change = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setShipCostInputs(
                shipCostInputs.map(item => {
                    if (item.id === e.target.id) {
                        return { ...item, [e.target.name]: e.target.value }
                    }
                    return item
                }),
            )
        },
        [shipCostInputs],
    )

    return (
        <div className='flex flex-col' style={{ flex: 2 }}>
            <div className='flex flex-col h-full border-solid border border-mainBackgroundColor m-4 rounded-lg' style={{ flex: 2 }}>
                <div className='mt-5 ml-44'>
                    <MediumItalic>~Основная информация~</MediumItalic>
                </div>
                <div className='flex justify-center w-full mt-9 gap-8'>
                    <div>
                        <div className='flex flex-col gap-2 justify-start'>
                            <InputWrapper name='Название'>
                                <TextInput
                                    type='text'
                                    placeholder='Название'
                                    value={formState?.title}
                                    onChange={e => onChangeField('title', e.target.value)}
                                />
                            </InputWrapper>
                            <InputWrapper name='Дальность (км)'>
                                <TextInput
                                    placeholder='Дальность (км)'
                                    value={formState?.range || ''}
                                    type='number'
                                    onChange={e => onChangeField('range', e.target.value)}
                                />
                            </InputWrapper>
                            <InputWrapper name='Масса (кг)'>
                                <TextInput
                                    placeholder='Масса (кг)'
                                    type='number'
                                    value={formState?.mass || ''}
                                    onChange={e => onChangeField('mass', e.target.value)}
                                />
                            </InputWrapper>
                        </div>
                    </div>
                    <div className='flex flex-col items-end gap-2'>
                        <InputWrapper name='Размеры груза (м²)'>
                            <TextInput
                                placeholder='Размеры груза (м²)'
                                type='number'
                                value={formState?.cargo_size || ''}
                                onChange={e => onChangeField('cargo_size', e.target.value)}
                            />
                        </InputWrapper>
                        <TextArea
                            value={formState.comment || ''}
                            onChange={e => onChangeField('comment', e.currentTarget.value)}
                            placeholder='Комментарий'
                        />
                    </div>
                </div>
                <div className='flex w-full p-4 h-full'>
                    <Button
                        onClick={() =>
                            openDeleteCourierGroupDialog({
                                group_id: currentCourierGroup?.item.group_id,
                                title: currentCourierGroup?.item.title,
                            })
                        }
                        variant={EButtonVariant.QUARTERY}
                        className='self-end'>
                        <RegularText className='underline !text-darkBlue-900'>Удалить группу</RegularText>
                    </Button>
                </div>
                <div className='m-4 mt-8'>
                    <div className='flex  justify-between'>
                        <MediumItalic>Стоимость доставки (Сервис доставки)</MediumItalic>
                    </div>
                    <div className='flex justify-between'>
                        <div>
                            {shipCostInputs.map((item, index) => (
                                <div key={item.id} className='mt-2 mb-2 flex gap-1 align-items-center'>
                                    {index + 1})
                                    <TextInput
                                        name='from'
                                        style={{ maxWidth: '150px' }}
                                        placeholder='Расстояние (от)'
                                        type='number'
                                        value={item.from}
                                        onChange={change}
                                        id={item.id}
                                    />
                                    <span style={{ marginRight: '5px' }}>км</span>
                                    <TextInput
                                        name='to'
                                        style={{ maxWidth: '150px' }}
                                        placeholder='Расстояние (до)'
                                        type='number'
                                        value={item.to}
                                        onChange={change}
                                        id={item.id}
                                    />
                                    <span style={{ marginRight: '5px' }}>км</span>
                                    <TextInput
                                        onChange={change}
                                        name='price'
                                        style={{ maxWidth: '100px' }}
                                        placeholder='Цена'
                                        type='number'
                                        value={item.price}
                                        id={item.id}
                                    />
                                    {index === 0 ? null : (
                                        <Icon type='cross' style={{ cursor: 'pointer' }} onClick={() => deleteInputs(item.id)} />
                                    )}
                                </div>
                            ))}

                            {shipCostInputs.length > 5 ? null : (
                                <MediumItalic style={{ fontSize: '13px', cursor: 'pointer' }} onClick={addInputs}>
                                    Добавить еще
                                </MediumItalic>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-end gap-4 p-4'>
                <Button icon='refresh' onClick={handleReset}>
                    <RegularText>Обновить</RegularText>
                </Button>
                <Button loader={loading} variant={EButtonVariant.BLUE} icon='save' onClick={onClickEdit} disabled={!isFormValid}>
                    <RegularText>Сохранить</RegularText>
                </Button>
                <Button onClick={closeModal}>
                    <RegularText>Выйти</RegularText>
                </Button>
            </div>
        </div>
    )
}
