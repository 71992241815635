import { api } from 'src/axios'
import { AxiosResponse } from 'axios'
import { HttpStatusCode } from 'src/types'

import { MainStatisticsFilters } from '../../atoms/mainStatistics/getMainStatisticsAtom'

import {
    AllCourierParams,
    CreateCourierGroupRequest,
    CreateCourierRequest,
    LoginRequest,
    LoginResponse,
    CreateClientGroupRequest,
    EditClientDBRequest,
    GetAllClientsDbParams,
    CreateClientDBRequest,
    ChangeBonusRequest,
    CreateOrderRequest,
    EditCourierRequest,
    CancelOrderRequest,
    AssignOrderRequest,
    DeleteOrderRequest,
    CreateCourierAvailabilityRequest,
    GetCourierDeliveriesRequest,
    DeleteCourierRequest,
    FireCourierRequest,
    CreateRestGroupRequest,
    DeleteRestGroupRequest,
    CreateRestRequest,
    GetRestRequest,
    EditRestRequest,
    GetRestOrdersRequest,
    GetClientOrderRequest,
    UploadFileResponse,
    ReAssignCourierGroupRequest,
    DeleteCourierGroupRequest,
    EditCourierGroupRequest,
    BlockRestRequest,
    EditCourierAvailabilityRequest,
    FormOrderRequest,
    Admin,
    CreateAdminRequest,
    EditAdminRequest,
    GetRestAccountingRequest,
    GetRestAccountingOrdersRequest,
    PayRestAccountingOrdersRequest,
    GetCouriersAccountingRequest,
    GetCourierAccountingOrdersRequest,
    PayCourierAccountingOrdersRequest,
    DeleteClientParams,
    SimpleSuccessResponse,
    EditClientGroupRequest,
    City,
    CreateAdminAvailabilityRequest,
    EditAdminAvailabilityRequest,
    ClientGroupEditRequest,
    GetOrdersFilters,
    DeleteAdminAvailabilityRequest,
    NotifyRestaurantRequest,
    ClientGroupDeleteRequest,
    CreateCourierOrderRequest,
    GetCourierDeliveryOrdersRequest,
    CourierDeliveryOrdersResponse,
    CourierTakeOrderRequest,
    GetRestStatsRequest,
    DeleteAdminRequest,
    FireAdminRequest,
    CityAsAddress,
    HeatmapPointsType,
    GetPriceAndDistanceRequest,
    GetPriceAndDistanceResponse,
} from './types'
import {
    Courier,
    ClientGroup,
    CourierGroup,
    Order,
    ClientDB,
    Delivery,
    RestGroup,
    RestGroupSchema,
    Rest,
    RestOrder,
    ClientOrder,
    RestAccounting,
    RestAccountingOrders,
    CouriersAccountingOrders,
    CouriersAccounting,
    CourierPayments,
    RestStatsResponse,
    FinancesAccounting,
    FinancesCourierAccounting,
} from './LocaliRepositorySchemas'
import { CourierAvailability } from './LocaliRepositorySchemas/CourierAvailabilitySchema'
import { CancelClientOrderRequest, ClientGiveGroupBonusRequest, DeleteClientOrderRequest } from './types/ClientDB'
import { AdminAvailability } from './LocaliRepositorySchemas/AdminAvailabilitySchema'
import { MainSettingsRequest } from './types/mainSettings'
import {
    CreateMassNotificationRequest,
    CreateSmsTemplateRequest,
    DeleteMassNotificationRequest,
    DeleteSmsTemplateRequest,
    EditSmsTemplateRequest,
    FastNotificationRequest,
    MassNotification,
    SmsTemplate,
} from './types/pushNotifications'
import { CreateCourierPaymentRequest, GetCourierPaymentRequest } from './types/createCourierPayment'
import {
    AdminPaymentsResponse,
    CalculateAdminPaymentRequest,
    CalculateAdminPaymentResponse,
    CreateAdminPaymentRequest,
    GetAdminPaymentRequest,
} from './types/createAdminPayment'
import { param } from 'ts-interface-checker'

class LocaliRepository {
    private static instance: LocaliRepository

    static getInstance(): LocaliRepository {
        if (!LocaliRepository.instance) {
            LocaliRepository.instance = new LocaliRepository()
        }

        return LocaliRepository.instance
    }

    // ! Login
    async login(request: LoginRequest): Promise<AxiosResponse<LoginResponse>> {
        try {
            return await api.post<LoginResponse>('/admin/login', {
                phoneNumber: request.phoneNumber,
                password: request.password,
            })
        } catch (e) {
            throw new Error('Auth request failed')
        }
    }

    // ! Couriers

    async getCourierGroups(): Promise<CourierGroup[]> {
        try {
            const { data } = await api.get<CourierGroup[]>('/admin/groups?admin=true')

            return data
        } catch (e) {
            throw e
        }
    }

    async getAllCouriers(params: AllCourierParams): Promise<Courier[]> {
        try {
            const { data } = await api.get<Courier[]>('/admin/couriers', {
                params: {
                    ...params,
                },
            })
            return data
        } catch (e) {
            throw e
        }
    }

    async createCourierGroup(req: CreateCourierGroupRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/group', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async getCourierAvailability(courierId: string): Promise<CourierAvailability[]> {
        try {
            const { data } = await api.get<CourierAvailability[]>('/admin/courier-avial', {
                params: {
                    courier_id: courierId,
                },
            })

            return data
        } catch (e) {
            throw e
        }
    }

    async createCourierAvailability(req: CreateCourierAvailabilityRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/courier-avial', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async editCourierAvailability(req: EditCourierAvailabilityRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.put('/admin/courier-avial', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async createCourier(req: CreateCourierRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/couriers', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async editCourier(req: EditCourierRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.put('/admin/couriers', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async deleteCourier(req: DeleteCourierRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/delete-courier', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async fireCourier(req: FireCourierRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/fire-courier', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async reAssignCourierGroup(req: ReAssignCourierGroupRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/reassign-courier-group', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async deleteCourierGroup(req: DeleteCourierGroupRequest) {
        try {
            const response = await api.post('/admin/delete-group', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async editCourierGroup(req: EditCourierGroupRequest) {
        try {
            const response = await api.put('/admin/group', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async deleteCourierAvailability(id: string) {
        try {
            const response = await api.delete('/admin/courier-avial', {
                params: {
                    avialschedule_id: id,
                },
            })
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async formOrder(req: FormOrderRequest) {
        try {
            const response = await api.post('/admin/independent-order', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async getClientData(tabNumber: string): Promise<ClientDB['clientData']> {
        try {
            const response = await api.get<ClientDB['clientData']>('/admin/get-client-data', {
                params: {
                    tabNumber,
                },
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getCouriersAccounting(params?: GetCouriersAccountingRequest): Promise<CouriersAccounting> {
        try {
            const response = await api.get<CouriersAccounting>('/admin/courier/payments', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getCourierAccountingOrders(params?: GetCourierAccountingOrdersRequest): Promise<CouriersAccountingOrders> {
        try {
            const response = await api.get<CouriersAccountingOrders>('/admin/courier/payments/orders', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async payCourierAccountingOrders(req?: PayCourierAccountingOrdersRequest): Promise<FinancesCourierAccounting> {
        try {
            const response = await api.post<FinancesCourierAccounting>('/admin/pay-courier', req)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getCourierAccountingPayments(params?: GetCourierPaymentRequest): Promise<CourierPayments> {
        try {
            const response = await api.get<CourierPayments>('/admin/courier-payment', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createCourierPayment(req: CreateCourierPaymentRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/courier-payment', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async createCourierOrder(req: CreateCourierOrderRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/couriers/create-order', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    // async courierTakeOrder(req: CourierTakeOrderRequest):Promise<HttpStatusCode> {
    //   try{
    //     const response = await api.post('/couriers/take-order', req);
    //     return response.status;
    //   }catch(e){
    //     throw e;
    //   }
    // }
    // ! Clients
    async createClientGroup(req: CreateClientGroupRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/client-group', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async clientGroupEdit(req: ClientGroupEditRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.put('/admin/client-group', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async clientGroupDelete(params: ClientGroupDeleteRequest): Promise<SimpleSuccessResponse> {
        try {
            const response = await api.delete('/admin/client-group', { params })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createClientDB(req: CreateClientDBRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/client', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async editClientDB(req: EditClientDBRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.put('/admin/client', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async editClientGroup(req: EditClientGroupRequest): Promise<SimpleSuccessResponse> {
        try {
            const response = await api.put('/admin/client', req)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getClientGroups(): Promise<ClientGroup[]> {
        try {
            const response = await api.get<ClientGroup[]>('/admin/client-group')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getClientsDB(params: GetAllClientsDbParams): Promise<any> {
        try {
            const response = await api.get<ClientDB[]>('/admin/clients', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteClient(params: DeleteClientParams): Promise<SimpleSuccessResponse> {
        try {
            const response = await api.delete<SimpleSuccessResponse>('/admin/client', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async changeBonus(req: ChangeBonusRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.put<HttpStatusCode>('/admin/client-bonus', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async cancelClientOrder(req: CancelClientOrderRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post<HttpStatusCode>('/admin/cancel-order', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async deleteClientOrder(req: DeleteClientOrderRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post<HttpStatusCode>('/admin/delete-order', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async giveGroupBonus(req: ClientGiveGroupBonusRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post<HttpStatusCode>('/admin/give-group-bonus', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    // ! Push Notifications

    async getSmsTemplates(): Promise<SmsTemplate[]> {
        try {
            const response = await api.get<SmsTemplate[]>('/push')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createSmsTemplate(req: CreateSmsTemplateRequest): Promise<SmsTemplate> {
        try {
            const response = await api.post<SmsTemplate>('/push/create', req)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editSmsTemplate(req: EditSmsTemplateRequest): Promise<SmsTemplate> {
        try {
            const response = await api.put<SmsTemplate>('/push', req)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteSmsTemplate(req: DeleteSmsTemplateRequest): Promise<SmsTemplate> {
        try {
            const response = await api.delete<SmsTemplate, AxiosResponse<SmsTemplate>>('/push', { params: req })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getMassNotifications(): Promise<MassNotification[]> {
        try {
            const response = await api.get<MassNotification[]>('/push/mass-send')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createMassNotifications(req: CreateMassNotificationRequest): Promise<any> {
        try {
            const response = await api.post<any>('/push/mass-send', req)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteMassNotifications(req: DeleteMassNotificationRequest): Promise<SimpleSuccessResponse> {
        try {
            const response = await api.delete<SmsTemplate, AxiosResponse<SimpleSuccessResponse>>('/push/mass-send', { params: req })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async sendFastNotification(req: FastNotificationRequest): Promise<SimpleSuccessResponse> {
        try {
            const response = await api.post<SmsTemplate, AxiosResponse<SimpleSuccessResponse>>('/push/quick-send', req)
            return response.data
        } catch (e) {
            throw e
        }
    }

    // ! Orders

    async getOrders(params?: GetOrdersFilters | object): Promise<Order[]> {
        try {
            const response = await api.get<Order[]>('/orders', { params })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getAllOrders(): Promise<Order[]> {
        try {
            const response = await api.get<Order[]>('/orders')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createOrder(req: CreateOrderRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post<Order[]>('/admin/independent-order', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async cancelOrder(req: CancelOrderRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post<Order[]>('/admin/cancel-order', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async deleteOrder(req: DeleteOrderRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post<Order[]>('/admin/delete-order', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async notifyRestaurant(req: NotifyRestaurantRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post<SimpleSuccessResponse>('/admin/rests/notify', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async assignOrder(req: AssignOrderRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/assign-order', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async getClientOrders(params: GetClientOrderRequest): Promise<ClientOrder[]> {
        try {
            const response = await api.get<ClientOrder[]>('admin/client-deliv', {
                params,
            })
            return response.data
        } catch (e) {
            console.log(e)
            throw e
        }
    }

    async getPriceAndDistance(req: GetPriceAndDistanceRequest): Promise<GetPriceAndDistanceResponse> {
        try {
            const response = await api.post<GetPriceAndDistanceResponse>('/orders/get-price-admin', req)
            return response.data
        } catch (e) {
            throw e
        }
    }

    // ! Deliveries
    async getDeliveries(params: GetCourierDeliveriesRequest): Promise<Delivery> {
        try {
            const response = await api.get<Delivery>('/admin/courier-deliv', { params })
            return response.data
        } catch (e) {
            throw e
        }
    }

    // ! Restaraunts groups

    async getRestGroup(): Promise<RestGroup[]> {
        try {
            const response = await api.get<RestGroup[]>('/admin/rest-group')
            return Promise.all(response.data.map(gr => RestGroupSchema.parse(gr)))
        } catch (e) {
            throw e
        }
    }

    async createRestGroup(req: CreateRestGroupRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/rest-group', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async deleteRestGroup(req: DeleteRestGroupRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/delete-rest-gr', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async createRest(req: CreateRestRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/restaurants', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async editRest(req: EditRestRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.put('/admin/restaurants', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async getRest(params: GetRestRequest): Promise<Rest[]> {
        try {
            const response = await api.get<Rest[]>('/admin/restaurants', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getRestOrders(params: GetRestOrdersRequest): Promise<RestOrder[]> {
        try {
            const response = await api.get<RestOrder[]>('/admin/rest-deliveries', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getRestStats(params: GetRestStatsRequest): Promise<RestStatsResponse> {
        try {
            const response = await api.get<RestStatsResponse>('/admin/rest-stats', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }
    async blockRest(req: BlockRestRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/restaurant-block', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async deleteRest(restId: string) {
        try {
            const response = await api.delete('/admin/restaurant', {
                params: {
                    rest_id: restId,
                },
            })
            return response.status
        } catch (e) {
            throw e
        }
    }

    async getRestAccounting(params?: GetRestAccountingRequest): Promise<RestAccounting> {
        try {
            const response = await api.get<RestAccounting>('/admin/rests/payments', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getRestAccountingOrders(params?: GetRestAccountingOrdersRequest): Promise<RestAccountingOrders> {
        try {
            const response = await api.get<RestAccountingOrders>('/admin/rests/payments/orders', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async payRestAccountingOrders(req?: PayRestAccountingOrdersRequest): Promise<FinancesAccounting> {
        try {
            const response = await api.post<FinancesAccounting>('/admin/pay-rest', req)
            return response.data
        } catch (e) {
            throw e
        }
    }

    // ! Files
    async uploadFiles(formData: FormData): Promise<UploadFileResponse> {
        try {
            const response = await api.post('ext/upload', formData)
            return response.data
        } catch (e) {
            throw e
        }
    }

    // ! Administrators

    async getAdmins(): Promise<AxiosResponse<Admin[]>> {
        try {
            const response = await api.get<Admin[]>('/admin/get-admins')
            return response
        } catch (e) {
            throw e
        }
    }

    async createAdmin(req: CreateAdminRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/create-admin', req)
            return response.status
        } catch (e) {
            throw e
        }
    }
    async deleteAdmin(req: DeleteAdminRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.delete('/admin/admin', {
                params: req,
            })
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }
    async fireAdmin(req: FireAdminRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/fire-admin', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }
    async editAdmin(req: EditAdminRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.put('/admin/update-admin', req)
            return response.status
        } catch (e) {
            throw e
        }
    }

    async getAdminAvailability(adminId: string): Promise<AdminAvailability[]> {
        try {
            const { data } = await api.get<AdminAvailability[]>('/admin/admin-avial', {
                params: {
                    admin_id: adminId,
                },
            })

            return data
        } catch (e) {
            throw e
        }
    }
    async createAdminAvailability(req: CreateAdminAvailabilityRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/admin-avial', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async editAdminAvailability(req: EditAdminAvailabilityRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.put('/admin/admin-avial', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async deleteAdminAvailability(req: DeleteAdminAvailabilityRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.delete('/admin/admin-avial', {
                params: req,
            })
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async createAdminPayment(req: CreateAdminPaymentRequest): Promise<HttpStatusCode> {
        try {
            const response = await api.post('/admin/admin-payment', req)
            return response.status as HttpStatusCode
        } catch (e) {
            throw e
        }
    }

    async getAdminPayment(params?: GetAdminPaymentRequest): Promise<AdminPaymentsResponse> {
        try {
            const response = await api.get<AdminPaymentsResponse>('/admin/admin-payments', {
                params,
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    async calculateAdminPayment(req: CalculateAdminPaymentRequest): Promise<CalculateAdminPaymentResponse> {
        try {
            const response = await api.post<CalculateAdminPaymentResponse>('/admin/realtime-calculate', req)
            return response.data
        } catch (e) {
            throw e
        }
    }

    // ! Cities

    async getCities(params: { query: string; isAddress?: boolean }): Promise<City[]> {
        try {
            const response = await api.get<{ suggestions: City[] } | CityAsAddress[][]>('/admin/cities', { params })
            if (params.isAddress) {
                return (response.data as CityAsAddress[][])[0].map(item => ({
                    place_name: item.address,
                    coordinates: [item.longitude, item.latitude],
                }))
            }
            return (response.data as { suggestions: City[] }).suggestions
        } catch (e) {
            throw e
        }
    }

    // ! Main Statistics

    async getMainStatistics(params: MainStatisticsFilters): Promise<MainSettingsRequest> {
        try {
            const response = await api.get<MainSettingsRequest>('/admin/full-stats', { params })
            return response.data
        } catch (e) {
            throw e
        }
    }

    // ! Maps

    async couriersMapRequest(): Promise<SimpleSuccessResponse> {
        try {
            const response = await api.get<SimpleSuccessResponse>('/admin/courier-map')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async restMapRequest(): Promise<SimpleSuccessResponse> {
        try {
            const response = await api.get<SimpleSuccessResponse>('/admin/rest-map')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getHeatmapPoints(): Promise<HeatmapPointsType> {
        try {
            const response = await api.get<HeatmapPointsType>('/admin/heatmap')
            return response.data
        } catch (e) {
            throw e
        }
    }
}

const LokaliApi = LocaliRepository.getInstance()

export { LokaliApi }
