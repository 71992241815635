import { useAtomValue, useSetAtom } from 'jotai'
import React, { useMemo } from 'react'
import { openEditAdminAtom } from 'src/atoms/admins/adminModalsAtom'
import { currentAdminAtom, getAdminsAtom, setCurrentAdminAtom } from 'src/atoms/admins/adminsTableAtom'
import { adminsSearchQueryAtom } from 'src/atoms/admins/adminsSearchAtom'
import { GridTable, GridTableHead, GridTableItem, PassportImageHover } from 'src/components'
import moment from 'moment/moment'

const THEADS = [
    'Таб.номер',
    'ИНН',
    'Телефон',
    'Фамилия',
    'Имя',
    'Отчество',
    'Фото',
    'Пол',
    'Дата рождения',
    'Статус смены',
    'Дата регистрации',
    'Дата увольнения',
    'Комментарий',
]

export const AdminsTable = (): JSX.Element => {
    const currentAdmin = useAtomValue(currentAdminAtom)
    const setCurrentAdmin = useSetAtom(setCurrentAdminAtom)
    const editAdmin = useSetAtom(openEditAdminAtom)
    const { data } = useAtomValue(getAdminsAtom)
    const searchQuery = useAtomValue(adminsSearchQueryAtom)

    const filteredData = useMemo(() => {
        if (!searchQuery) return data
        return data.filter(admin => Object.values(admin).some(value => String(value).toLowerCase().includes(searchQuery.toLowerCase())))
    }, [data, searchQuery])

    return (
        <div className='w-full h-full'>
            <GridTable
                theads={THEADS.map(head => (
                    <GridTableHead key={head} title={head} />
                ))}>
                {filteredData.map(admin => (
                    <tr key={admin.admin_id}>
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={admin.tabNumber}
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={admin.passData.inn}
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={admin.phone}
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={admin.firstName}
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={admin.middleName}
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={admin.lastName}
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={<PassportImageHover photoUrl={admin.passData?.adminPhoto || ''} />}
                        />

                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={admin.gender}
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={
                                admin.birthday === null || admin.birthday === 'Invalid Date' || admin.birthday === 'any'
                                    ? 'Не указана'
                                    : admin.birthday
                            }
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={admin.isWorkingToday ? 'Уволен' : 'Работает'}
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={moment(admin.createdAt).utc().format('DD.MM.YYYY')}
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={admin.fireDate === null ? 'не уволен' : admin.fireDate}
                        />
                        <GridTableItem
                            isActive={currentAdmin?.admin_id === admin.admin_id}
                            onDoubleClick={admin.fireDate == null ? () => editAdmin() : undefined}
                            onClick={admin.fireDate === null ? () => setCurrentAdmin(admin) : undefined}
                            child={admin.comment}
                        />
                    </tr>
                ))}

                <div style={{ height: '300px' }} />
            </GridTable>
        </div>
    )
}
