import { PropsWithChildren, RefObject, forwardRef, useState } from 'react'
import { MapContainer, MapContainerProps, TileLayer, ZoomControl } from 'react-leaflet'
import { Map } from 'leaflet'
import classNames from 'classnames'

import GroznyiCoordinates from '../../utils/groznyiCoordinates'

import styles from './styles.module.scss'
import axios from 'axios'

type AppMapProps = MapContainerProps & {
    mapHeight?: number | string
    ref: RefObject<Map>
    mapClassname?: string
    city?: string
}

const AppMap = forwardRef<Map, AppMapProps>((props: PropsWithChildren<AppMapProps>): JSX.Element => {
    const { children, city, center = GroznyiCoordinates, zoom = 13, scrollWheelZoom = true, mapHeight, ref, mapClassname, ...rest } = props

    return (
        <div className='relative w-full h-full'>
            <MapContainer
                ref={ref}
                className={classNames(styles.map, mapClassname)}
                style={{
                    height: mapHeight,
                }}
                attributionControl={true}
                zoomControl={false}
                scrollWheelZoom={scrollWheelZoom}
                center={center}
                zoom={zoom}
                {...rest}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <ZoomControl position='bottomright' zoomInText='+' zoomOutText='-' />
                {children}
            </MapContainer>
        </div>
    )
})

export { AppMap }
