import { useAtomValue } from 'jotai'
import React, { useEffect, useState } from 'react'
import { GridTable, GridTableHead, GridTableItem, MediumText, RegularText } from 'src/components'
import { datePeriodOptions, DateUtils, EDateRangeOptions } from 'src/utils'
import { useSetAtom } from 'jotai/index'

import { getRestOrdersAtom, updateRestOrdersFiltersAtom } from '../../atoms/restaraunts/restAtom'
import { DateFilterAuto } from '../../components/DateFilterAuto'
import { statusNames } from '../../utils/statusNames'
import { RestOrder } from '../../services'
import { DateFilterSelect } from '../../components/DateFilterSelect'

const THEAD = [
    'Номер заказа',
    'Статус',
    'Дата/время поступление заказа',
    'Дата/время взятия заказа',
    'Дата/время  заказа',
    'Сумма заказа',
    'Тип оплаты',
    'Состав заказа',
    'ФИО курьера',
    'Таб номер курьера',
    'Телефон курьера',
    'Время доставки',
    'Имя клиента',
    'Адрес клиента',
    'Номер клиента',
    'Комментарий клиента',
]
type DateType = {
    dateFrom: string
    dateTo: string
}
export const ModalEditRestOrders = (): JSX.Element => {
    const { data }: { data: RestOrder[] } = useAtomValue(getRestOrdersAtom)
    const updateFilters = useSetAtom(updateRestOrdersFiltersAtom)
    const [dates, setDates] = useState<DateType | object>({})

    const setDate = (fieldName: 'dateFrom' | 'dateTo') => (val: string) => {
        setDates(prev => ({ ...prev, [fieldName]: val }))
    }

    const DishDetails = ({ dishes }) => {
        if (!dishes || !Array.isArray(dishes) || dishes.length === 0) {
            return <span>Нет данных</span>
        }

        return (
            <ul>
                {dishes.map((dish, index) => (
                    <li key={index}>
                        {dish.title} (x{dish.amount}) - {dish.dishPrice} руб.
                    </li>
                ))}
            </ul>
        )
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            updateFilters(dates)
        }, 300)

        return () => clearTimeout(timer)
    }, [dates])

    return (
        <div className='py-9 px-4'>
            <div>
                <DateFilterSelect
                    dateFrom={(dates as DateType)?.dateFrom || ''}
                    dateTo={(dates as DateType)?.dateTo || ''}
                    setDateFrom={setDate('dateFrom')}
                    setDateTo={setDate('dateTo')}
                    selectProps={{ options: datePeriodOptions }}
                />
                <div className='py-2'>
                    <GridTable
                        maxHeight={'60vh'}
                        theads={THEAD.map(head => (
                            <GridTableHead title={head} className='text-nowrap whitespace-nowrap p-4 bg-aqua-400' key={head} />
                        ))}>
                        {data.map(el => (
                            <tr>
                                <GridTableItem child={el.orderNumber} />
                                <GridTableItem child={statusNames[el.status || ''] || el.status} />
                                <GridTableItem child={el.createdAt ? DateUtils.formatDateTime(el.createdAt) : '-'} />
                                <GridTableItem child={el.orderTookDate ? DateUtils.formatDateTime(el.orderTookDate) : '-'} />
                                <GridTableItem child={el.deliveryDate ? DateUtils.formatDateTime(el.deliveryDate) : '-'} />
                                <GridTableItem child={el.price} />
                                <GridTableItem child={el.paymentType === 'cash' ? 'Наличными' : 'Картой'} />
                                <GridTableItem child={<DishDetails dishes={el.dishIdArray || []} />} />
                                <GridTableItem child={el.courierName || '-'} />
                                <GridTableItem child={el.courierTabNumber || '-'} />
                                <GridTableItem child={el.courierPhoneNumber || '-'} />
                                <GridTableItem child={el.deliveryTime || '-'} />
                                <GridTableItem child={el.clientName || '-'} />
                                <GridTableItem child={el.deliveryAddress || '-'} />
                                <GridTableItem child={el.clientPhoneNumber || '-'} />
                                <GridTableItem child={el.comment || '-'} />
                            </tr>
                        ))}
                    </GridTable>
                </div>
            </div>
        </div>
    )
}
