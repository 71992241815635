import { SuspenseWithSpinner, Tab, Tabs } from 'src/components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAtom, useSetAtom } from 'jotai'

import { createAllDaysAtom, createRestAtom, currentWorkTimeModeAtom } from '../../atoms/restaraunts/createRestAtom'

import { ModalCreateRestInfo } from './ModalCreateRestInfo'
import { ModalCreateRestInfoFooter } from './ModalCreateRestInfoFooter'
import { ModalCreateRestWorkTime } from './ModalCreateRestWorkTime'
import { ModalCreateRestIntegration } from './ModalCreateRestIntegration'
import { CreateRestFormData, CreateRestSchema } from './CreateRestSchema'
import { useEffect, useState } from 'react'
import { ModalTypeOrder } from './ModalTypeOrder'

export const ModalCreateRest = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useAtom(currentWorkTimeModeAtom)
    const methods = useForm<CreateRestFormData>({
        resolver: zodResolver(CreateRestSchema),
        defaultValues: {
            orderMethods: [],
            deliveryEnabled: true,
            terminalEnabled: false,
            pickupEnabled: false,
        },
    })

    const createRest = useSetAtom(createRestAtom)

    const onSubmit = async (data: CreateRestFormData) => {
        setLoading(true)
        // @ts-ignore
        await createRest({ ...data, checkbox: mode })
        setLoading(false)
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Tabs
                    tabs={[
                        {
                            tabOption: {
                                tabName: 'Инфо',
                            },
                            child: (
                                <SuspenseWithSpinner>
                                    <ModalCreateRestInfo />
                                </SuspenseWithSpinner>
                            ),
                            footerChild: <ModalCreateRestInfoFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Время работы',
                            },
                            child: <ModalCreateRestWorkTime />,
                            footerChild: <ModalCreateRestInfoFooter loading={loading} />,
                        },
                        {
                            tabOption: {
                                tabName: 'Настройки интеграции',
                            },
                            child: <ModalCreateRestIntegration />,
                            footerChild: <ModalCreateRestInfoFooter loading={loading} />,
                        },
                        // {
                        //     tabOption: {
                        //         tabName: 'Тип заказа',
                        //     },
                        //     child: <ModalTypeOrder />,
                        //     footerChild: <ModalCreateRestInfoFooter loading={loading} />,
                        // },
                    ]}
                />
            </form>
        </FormProvider>
    )
}
