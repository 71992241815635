import { SelectOption } from 'src/components'
import moment from 'moment'
import { Moment } from 'moment'

enum EDateRangeOptions {
    TODAY = 'Сегодня',
    CURRENT_WEEK = 'Текущая неделя',
    CURRENT_MONTH = 'Текущий месяц',
    CURRENT_YEAR = 'Текущий год',
    YESTERDAY = 'Вчера',
    LAST_WEEK = 'Прошлая неделя',
    LAST_MONTH = 'Прошлый месяц',
    LAST_YEAR = 'Прошлый год',
    ALL_TIME = 'Всё время',
}

function getStartOfDay(date: Moment) {
    return date.clone().startOf('day')
}

function getEndOfDay(date: Moment) {
    return date.clone().endOf('day')
}

function getDateRange(start: Moment, end: Moment) {
    return {
        start: getStartOfDay(start),
        end: getEndOfDay(end),
    }
}

const DateRangeDates = {
    TODAY: getDateRange(moment(), moment()),
    CURRENT_WEEK: getDateRange(moment().isoWeekday(1), moment()),
    CURRENT_MONTH: getDateRange(moment().startOf('month'), moment()),
    CURRENT_YEAR: getDateRange(moment().startOf('year'), moment()),
    YESTERDAY: getDateRange(moment().subtract(1, 'day'), moment().subtract(1, 'day')),
    LAST_WEEK: getDateRange(moment().subtract(1, 'week').isoWeekday(1), moment().subtract(1, 'week').isoWeekday(7)),
    LAST_MONTH: getDateRange(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')),
    LAST_YEAR: getDateRange(moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')),
    ALL_TIME: {
        start: moment('1900-01-01').startOf('day'), // Условная начальная дата
        end: moment(),
    },
}

const datePeriodOptions: SelectOption[] = [
    { label: 'Сегодня', value: EDateRangeOptions.TODAY, date: DateRangeDates.TODAY },
    { label: 'Текущая неделя', value: EDateRangeOptions.CURRENT_WEEK, date: DateRangeDates.CURRENT_WEEK },
    { label: 'Текущий месяц', value: EDateRangeOptions.CURRENT_MONTH, date: DateRangeDates.CURRENT_MONTH },
    { label: 'Текущий год', value: EDateRangeOptions.CURRENT_YEAR, date: DateRangeDates.CURRENT_YEAR },
    { label: 'Вчера', value: EDateRangeOptions.YESTERDAY, date: DateRangeDates.YESTERDAY },
    { label: 'Прошлая неделя', value: EDateRangeOptions.LAST_WEEK, date: DateRangeDates.LAST_WEEK },
    { label: 'Прошлый месяц', value: EDateRangeOptions.LAST_MONTH, date: DateRangeDates.LAST_MONTH },
    { label: 'Прошлый год', value: EDateRangeOptions.LAST_YEAR, date: DateRangeDates.LAST_YEAR },
    { label: 'Всё время', value: EDateRangeOptions.ALL_TIME, date: DateRangeDates.ALL_TIME },
]

export { datePeriodOptions, EDateRangeOptions }
