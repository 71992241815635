import React, { useState, useEffect } from 'react'
import { useAtom, useSetAtom, useAtomValue } from 'jotai'
import {
    MediumItalic,
    GridTable,
    MediumText,
    InputWrapper,
    AppSelect,
    TextInput,
    Button,
    EButtonVariant,
    RegularText,
    GridTableItem,
} from 'src/components'
import { createCourierPaymentAtom, getCourierPaymentAtom } from 'src/atoms/allCouriers/couriersAccountingAtom'

import { CreateCourierPaymentRequest, CourierPayments } from '../../services/LocaliRepository/types/createCourierPayment'
import { selectedCourierAtom } from '../../atoms/allCouriers/allCouriersAtom'
import { DateUtils } from '../../utils'

const THEADS = ['Дата', 'Сумма', 'Тип оплаты']

export const CourierPayment = (): JSX.Element => {
    const [paymentType, setPaymentType] = useState<string>('Оклад')
    const [amount, setAmount] = useState<string>('')
    const [payments, setPayments] = useState<CourierPayments[]>([])
    const createCourierPayment = useSetAtom(createCourierPaymentAtom)
    const [courierPaymentData, refreshCourierPayment] = useAtom(getCourierPaymentAtom)
    const selectedCourier = useAtomValue(selectedCourierAtom)

    useEffect(() => {
        if (selectedCourier?.item?.courier_id) {
            refreshCourierPayment()
        }
    }, [selectedCourier, refreshCourierPayment])

    useEffect(() => {
        if (courierPaymentData && Array.isArray(courierPaymentData.data)) {
            setPayments(courierPaymentData.data)
        }
    }, [courierPaymentData])

    const handlePayment = async () => {
        if (!amount || !paymentType || !selectedCourier?.item?.courier_id) {
            return
        }

        const req: CreateCourierPaymentRequest = {
            sum: amount,
            paymentType: paymentType,
            courier_id: selectedCourier.item.courier_id,
        }

        try {
            await createCourierPayment(req)
            console.log('Payment created successfully')
            refreshCourierPayment()
            setAmount('')
        } catch (error) {
            console.error('Error creating payment:', error)
        }
    }

    return (
        <div className='mt-7 ml-3'>
            <div className='flex justify-between p-4'>
                <MediumItalic className='my-3'>~История изменений~</MediumItalic>
                <div className='flex gap-2'>
                    <InputWrapper name='Тип оплаты'>
                        <AppSelect
                            options={[
                                { value: 'Оклад', label: 'Оклад' },
                                { value: 'Почасовая', label: 'Почасовая' },
                                { value: 'За путь', label: 'За путь' },
                            ]}
                            placeholder='Оклад'
                            value={{ value: paymentType, label: paymentType }}
                            onChange={option => setPaymentType(option?.value || 'Оклад')}
                        />
                    </InputWrapper>
                    <TextInput placeholder='руб' className='w-inputS' value={amount} onChange={e => setAmount(e.target.value)} />
                    <Button variant={EButtonVariant.SECONDARY} onClick={handlePayment}>
                        <RegularText>Оплатить</RegularText>
                    </Button>
                </div>
            </div>
            <div className='h-96 overflow-auto'>
                <GridTable
                    theads={THEADS.map(head => (
                        <MediumText className='p-4 bg-aqua-400 text-nowrap whitespace-nowrap' key={head}>
                            {head}
                        </MediumText>
                    ))}>
                    {payments.length > 0 ? (
                        payments.map(payment => (
                            <tr key={payment.courier_id}>
                                <GridTableItem child={<RegularText>{DateUtils.formatDate(payment.createdAt)}</RegularText>} />
                                <GridTableItem child={<RegularText>{payment.sum}</RegularText>} />
                                <GridTableItem child={<RegularText>{payment.paymentType}</RegularText>} />
                            </tr>
                        ))
                    ) : (
                        <GridTableItem child={<RegularText className='col-span-3 text-center'>Нет данных об оплатах</RegularText>} />
                    )}
                </GridTable>
            </div>
        </div>
    )
}
