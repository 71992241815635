import { Spinner, SuspenseWithSpinner, Tab, Tabs } from 'src/components'
import React from 'react'
import { useAtomValue, useSetAtom } from 'jotai'

import { currentRestAtom } from '../../atoms/restaraunts/restAtom'
import { InfoModalRest } from './InfoModalRest'
import { StaticOrderModal } from '../Orders/StaticOrderModal'
import { StaticRestModal } from './StaticRestModal'

export const ModalEditRest = (): JSX.Element => {
    const currentRest = useAtomValue(currentRestAtom)

    return (
        <>
            <InfoModalRest />,
        </>
    )
}
