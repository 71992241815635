import classNames from 'classnames'
import { useAtom, useAtomValue } from 'jotai'
import { getRestGroupAtom } from 'src/atoms/restaraunts/createRestGroupAtom'
import { restGroupAtom } from 'src/atoms/restaraunts/restGroupAtom'
import { GridTable, GridTableHead, GridTableItem } from 'src/components'
import { tableActiveClassName } from 'src/utils'

import styles from './styles.module.scss'
import { useEffect } from 'react'

export const RestGroupTable = (): JSX.Element => {
    const [restGroup, setRestGroup] = useAtom(restGroupAtom)
    const { data } = useAtomValue(getRestGroupAtom)

    useEffect(() => {
        return () => setRestGroup(null)
    }, [])

    return (
        <div>
            <GridTable
                className={styles.table}
                theads={[
                    <GridTableHead
                        key={1}
                        className='bg-aqua-400 p-3'
                        title='
                        Группа ресторанов'
                    />,
                ]}>
                <tr>
                    <td onClick={() => setRestGroup(null)} className={classNames('p-2', tableActiveClassName(restGroup === null))}>
                        Все рестораны
                    </td>
                </tr>
                {data &&
                    data.map(rest => (
                        <tr key={rest.restgroup_id}>
                            <GridTableItem
                                style={{ textAlign: 'left' }}
                                onClick={() => setRestGroup(rest)}
                                className={classNames('p-2', tableActiveClassName(rest.restgroup_id === restGroup?.restgroup_id))}
                                child={rest.title}
                            />
                        </tr>
                    ))}
            </GridTable>
        </div>
    )
}
