import React, { FC } from 'react'
import { GridTable, GridTableHead, GridTableItem, MediumText, Spinner, TextInput } from '../../../../components'
import styles from '../../../../features/Orders/styles.module.scss'
import { DateFilterSelect } from '../../../../components/DateFilterSelect'
import { datePeriodOptions } from '../../../../utils'
import { DateType, IReport } from '../../index'

interface IProps {
    dates: DateType | object
    data: IReport[]
    loading: boolean
    setDate: (date: 'dateFrom' | 'dateTo') => (val: string) => void
}

export const ReportCourer: FC<IProps> = ({ dates, setDate, data, loading }) => {
    return (
        <div>
            <div className={'flex gap-4'}>
                <DateFilterSelect
                    dateFrom={(dates as DateType)?.dateFrom || ''}
                    dateTo={(dates as DateType)?.dateTo || ''}
                    setDateFrom={setDate('dateFrom')}
                    setDateTo={setDate('dateTo')}
                    selectProps={{ options: datePeriodOptions }}
                />
            </div>

            {loading ? (
                <Spinner />
            ) : (
                <div className='mt-4'>
                    <GridTable
                        className={styles.table}
                        // style={{ height: window.innerHeight / (window.innerWidth / 2018) - 148 + 'px' }}
                        theads={[
                            'Название ресторана',
                            'Тип заказа',
                            'Новый заказ - Заказ принят',
                            'Заказ принят - Заказ у курьрера',
                            'Заказ готов - Заказ доставлен',
                            'Время заказа',
                        ].map(el => (
                            <GridTableHead key={el} title={el} />
                        ))}>
                        {data.map(i => (
                            <tr>
                                <GridTableItem style={{ borderBottom: '1px solid black' }} child={i?.title} />
                                <GridTableItem style={{ borderBottom: '1px solid black' }} child={'доставка'} />
                                <GridTableItem
                                    style={{ borderBottom: '1px solid black' }}
                                    child={i?.stats ? i.stats[1]?.stages?.new : '0'}
                                />
                                <GridTableItem
                                    style={{ borderBottom: '1px solid black' }}
                                    child={i?.stats ? i.stats[1]?.stages?.going : '0'}
                                />
                                <GridTableItem
                                    style={{ borderBottom: '1px solid black' }}
                                    child={i?.stats ? i.stats[1]?.stages?.shipping : '0'}
                                />
                                <GridTableItem
                                    style={{ borderBottom: '1px solid black' }}
                                    child={i?.stats ? i.stats[1]?.stages?.orderTime : '0'}
                                />
                            </tr>
                        ))}
                    </GridTable>
                </div>
            )}
            {!data.length && (
                <div className={'flex justify-center mt-2'}>
                    <MediumText style={{ textAlign: 'center' }}>Нет данных</MediumText>
                </div>
            )}
        </div>
    )
}
