import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { DateInput, MediumText, RegularText, SelectOption } from 'src/components'
import { AppSelect, AppSelectProps, ESelectVariant } from 'src/components/AppSelect'
import { RoundedWrapper } from 'src/components/RoundedWrapper'
import moment from 'moment'

import { datePeriodOptions, EDateRangeOptions } from '../../utils'
import styles from '../../pages/AccountingRestPage/styles.module.scss'

import { DateInputProps } from '../DateInput'

type DateFilterAutoProps = {
    dateFrom: string
    dateTo: string
    setDateFrom: (val: string) => void
    setDateTo: (val: string) => void

    defaultVal?: string
    rightElement?: ReactNode
    selectProps: AppSelectProps
    startDateInputProps?: DateInputProps
    endDateInputProps?: DateInputProps
    from?: string
    to?: string
    periodType?: string
    disabled?: boolean
    onClickRefresh?: (val: object) => void
}

const FORMAT = 'DD.MM.YYYY'

const checkIsIsoFormat = (date: string) => moment(date, moment.ISO_8601).isValid()

export const DateFilterSelect = (props: DateFilterAutoProps): JSX.Element => {
    const [range, setRange] = useState<SelectOption>()
    const [isInit, setIsInit] = useState(false)

    const handleSelect = useCallback(
        val => {
            let dateFromArg = ''
            let dateToArg = ''

            if (val?.value !== EDateRangeOptions.ALL_TIME) {
                dateFromArg = val.date.start.toISOString() || ''
                dateToArg = val.date.end.toISOString() || ''
            }

            if (val?.value === EDateRangeOptions.TODAY) {
                props.setDateFrom(moment().format('YYYY-MM-DD'))
                props.setDateTo(moment().format('YYYY-MM-DD'))
                setRange(val as SelectOption)
                return
            }

            if (val?.value === EDateRangeOptions.YESTERDAY) {
                props.setDateFrom(moment().subtract(1, 'days').format('YYYY-MM-DD'))
                props.setDateTo(moment().subtract(1, 'days').format('YYYY-MM-DD'))
                setRange(val as SelectOption)
                return
            }

            if (val?.value == EDateRangeOptions.ALL_TIME) {
                setRange(val as SelectOption)
                props.setDateFrom('')
                props.setDateTo('')
                return
            }

            props.setDateFrom(moment(dateFromArg).format('YYYY-MM-DD'))
            props.setDateTo(moment(dateToArg).format('YYYY-MM-DD'))
            setRange(val as SelectOption)
        },
        [props.selectProps?.value],
    )

    useEffect(() => {
        if (!isInit) {
            setIsInit(true)
            return
        }
        let dateFromArg = ''
        let dateToArg = ''
        if (range?.value !== EDateRangeOptions.ALL_TIME) {
            dateFromArg = range?.date?.start ? range.date.start.toISOString() : ''
            dateToArg = range?.date?.end ? range.date.end.toISOString() : ''
        }

        if (typeof props.onClickRefresh === 'function') {
            props.onClickRefresh({
                dateFromArg: moment(dateFromArg).format('YYYY-MM-DD'),
                dateToArg: moment(dateToArg).format('YYYY-MM-DD'),
            })
        }
    }, [range])

    // useEffect(() => {
    //   if (props.defaultVal) {
    //     handleSelect(datePeriodOptions.find(el => el.value === props.defaultVal));
    //   } else {
    //     handleSelect(datePeriodOptions.find(el => el.value === EDateRangeOptions.ALL_TIME));
    //   }
    // }, []);

    return (
        <div className='flex items-center justify-between w-full'>
            <div className='flex items-center'>
                <RegularText className='mr-4'>{props.periodType ?? 'За период'}</RegularText>
                <AppSelect
                    {...props.selectProps}
                    value={range}
                    onChange={handleSelect}
                    selectVariant={ESelectVariant.QUARTERY}
                    className='!w-52'
                />

                <RegularText className='px-3'>{props.from ?? 'C'}</RegularText>
                <input
                    onKeyDown={e => e.preventDefault()}
                    className={styles.input}
                    type='date'
                    value={props.dateFrom}
                    onChange={e => {
                        props.setDateFrom(e.target.value)
                        setRange({ label: '', value: '' })
                    }}
                />

                <RegularText className='px-3'>{props.to ?? 'По'}</RegularText>
                <input
                    onKeyDown={e => e.preventDefault()}
                    className={styles.input}
                    type='date'
                    value={props.dateTo}
                    onChange={e => {
                        props.setDateTo(e.target.value)
                        setRange({ label: '', value: '' })
                    }}
                />

                {typeof props.onClickRefresh === 'function' && (
                    <RoundedWrapper icon='refresh' className='ml-2' onClick={props.onClickRefresh} />
                )}
            </div>
            {props.rightElement}
        </div>
    )
}
