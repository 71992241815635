import classNames from 'classnames'
import { useAtomValue, useSetAtom } from 'jotai'
import React, { useState } from 'react'
import { getCourierAvailabilityAtom } from 'src/atoms/allCouriers/courierAvailabilityAtom'
import { setCourierScheduleModalAtom } from 'src/atoms/allCouriers/courierCreateModalAtom'
import { deleteCourierAvailAtom } from 'src/atoms/allCouriers/deleteCourierAvailAtom'
import { openEditCourierAvailAtom } from 'src/atoms/allCouriers/editCourierAvailAtom'
import { Button, MediumItalic, RegularText, GridTable, MediumText, GridTableHead, GridTableItem } from 'src/components'
import { CourierAvailability } from 'src/services'
import { DateUtils } from 'src/utils'

const THEADS = ['Название', 'Начало действия']

export const CourierEditAvailability = (): JSX.Element => {
    const setCourierScheduleAtom = useSetAtom(setCourierScheduleModalAtom)
    const deleteAvail = useSetAtom(deleteCourierAvailAtom)
    const editAvail = useSetAtom(openEditCourierAvailAtom)
    const { data } = useAtomValue(getCourierAvailabilityAtom)

    const [currentAvail, setCurrentAvail] = useState<CourierAvailability | undefined>(undefined)

    return (
        <div className='mx-4 my-6 flex gap-5 flex-col'>
            <div className='flex gap-14'>
                <MediumItalic>~График доступности курьера~</MediumItalic>
                <div className='flex gap-5'>
                    <Button onClick={() => setCourierScheduleAtom()}>
                        <RegularText>Добавить</RegularText>
                    </Button>
                    <Button disabled={!currentAvail} onClick={() => editAvail(currentAvail)}>
                        <RegularText>Редактировать</RegularText>
                    </Button>
                    <Button disabled={!currentAvail} onClick={() => deleteAvail(currentAvail?.avialschedule_id)}>
                        <RegularText className='!text-red-600'>Удалить</RegularText>
                    </Button>
                </div>
            </div>
            <div className='w-full h-96'>
                <GridTable
                    theads={THEADS.map(head => (
                        <GridTableHead key={head} title={head} />
                    ))}>
                    {data.map(o => (
                        <tr key={o.avialschedule_id}>
                            <GridTableItem
                                className={classNames(
                                    'p-2 cursor-pointer',
                                    currentAvail?.avialschedule_id === o.avialschedule_id && 'bg-darkBlue-700',
                                )}
                                onClick={() => setCurrentAvail(o)}
                                child={o.title}
                            />
                            <GridTableItem
                                className={classNames(
                                    'p-2 cursor-pointer',
                                    currentAvail?.avialschedule_id === o.avialschedule_id && 'bg-darkBlue-700',
                                )}
                                onClick={() => setCurrentAvail(o)}
                                child={DateUtils.formatDate(o.scheduleStart)}
                            />
                        </tr>
                    ))}
                </GridTable>
            </div>
        </div>
    )
}
