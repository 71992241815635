import { useAtomValue, useSetAtom } from 'jotai'
import React, { useMemo } from 'react'
import { allClientGroupsAtom, currentClientGroupAtom, setCurrentClientGroupAtom } from 'src/atoms/clientDb/clientGroupsAtom'
import { GridTable, GridTableHead, GridTableItem } from 'src/components'

import { editClientDBGroupAtom } from '../../atoms/clientDb/addClientDBGroupAtom'
import { useAtom } from 'jotai/index'
import { clientListPaginationAtom } from '../../atoms/clientDb/clientsAtom'

const THEADS = ['Группа клиентов', 'Прибыль']

export const ClientDatabaseGroup = (): JSX.Element => {
    const { data } = useAtomValue(allClientGroupsAtom)
    const currentGroup = useAtomValue(currentClientGroupAtom)
    const selectClientGroup = useSetAtom(setCurrentClientGroupAtom)
    const editClientDBGroup = useSetAtom(editClientDBGroupAtom)
    const [pagination, setPagination] = useAtom(clientListPaginationAtom)

    const totalOrderPrice = useMemo(() => (data?.length ? data.reduce((accum, item) => accum + (item.orderPrice || 0), 0) : 0), [data])

    return (
        <GridTable
            theads={THEADS.map(el => (
                <GridTableHead key={el} title={el} />
            ))}>
            <tr>
                <GridTableItem onClick={() => selectClientGroup(null)} isActive={currentGroup === null} child={'Все клиенты'} />
                <GridTableItem onClick={() => selectClientGroup(null)} isActive={currentGroup === null} child={totalOrderPrice} />
            </tr>
            {data &&
                data.map(clientGroup => {
                    const isActive = currentGroup?.clientgroup_id === clientGroup.clientgroup_id

                    /*if(!currentGroup?.clientgroup_id && idx === DEFAULT_GROUP) {
            selectClientGroup(clientGroup);
          }*/

                    return (
                        <tr key={clientGroup.clientgroup_id}>
                            <GridTableItem
                                onClick={() => {
                                    selectClientGroup(clientGroup)
                                    setPagination({ pageSize: 10, current: 1 })
                                }}
                                onDoubleClick={() => {
                                    editClientDBGroup()
                                }}
                                isActive={isActive}
                                child={clientGroup.title}
                            />
                            <GridTableItem
                                onClick={() => {
                                    selectClientGroup(clientGroup)
                                    setPagination({ pageSize: 10, current: 1 })
                                }}
                                onDoubleClick={() => {
                                    editClientDBGroup()
                                }}
                                isActive={isActive}
                                child={clientGroup.orderPrice || 0}
                            />
                        </tr>
                    )
                })}
        </GridTable>
    )
}
