import classNames from 'classnames'
import { useAtomValue } from 'jotai'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Icon } from 'src/assets/icons'
import { isNavbarFullAtom } from 'src/atoms/leftNavBar/leftNavBarAtom'
import { MediumItalic, MediumText, Spinner, Switch, TextInput } from 'src/components'
import { NormalModal } from '../../components/NormalModal'
import axios from 'axios'

interface Setting {
    MAX_DISTANCE_TO_RESTAURANT: number
    MAX_DISTANCE_BETWEEN_ORDERS: number
    MAX_DISTANCE_FOR_COURIER: number
    MAX_COUNT_ORDERS: number
    RADIUS: number
    autoDistributionEnabled: boolean
}

export const AutoDistribution = (): JSX.Element => {
    const isNavBarOpen = useAtomValue(isNavbarFullAtom)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState(false)
    const [settings, setSettings] = useState<Partial<Setting>>({
        autoDistributionEnabled: false,
        MAX_DISTANCE_BETWEEN_ORDERS: 0,
        MAX_DISTANCE_TO_RESTAURANT: 0,
        MAX_DISTANCE_FOR_COURIER: 0,
        MAX_COUNT_ORDERS: 0,
        RADIUS: 0,
    })

    const getDistanceSetting = async () => {
        setIsLoading(true)
        const { data } = await axios.get<{ settings: Setting }>('https://localockarmaps.ru/api/distance/settings')
        setIsLoading(false)
        if (data?.settings) {
            setSettings(data.settings)
        }
    }

    useEffect(() => {
        if (isOpen) {
            getDistanceSetting()
        }
    }, [isOpen])

    const change = (e: ChangeEvent<HTMLInputElement>) => {
        setSettings(pre => ({ ...pre, [e.target.name]: e.target.value }))
    }

    return (
        <>
            <NormalModal isOpen={isOpen} onClose={() => setIsOpen(false)} title={'Автораспределение'}>
                {isLoading ? (
                    <Spinner className={'p-2'} />
                ) : (
                    <div style={{ padding: '20px' }}>
                        <div>
                            <MediumText>Макс. расстояние до ресторана:</MediumText>{' '}
                            <TextInput
                                type={'number'}
                                value={settings?.MAX_DISTANCE_TO_RESTAURANT}
                                name={'MAX_DISTANCE_TO_RESTAURANT'}
                                onChange={change}
                            />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <MediumText>Макс. расстояние между заказами:</MediumText>
                            <TextInput
                                type={'number'}
                                value={settings?.MAX_DISTANCE_BETWEEN_ORDERS}
                                name={'MAX_DISTANCE_BETWEEN_ORDERS'}
                                onChange={change}
                            />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <MediumText>Макс. расстояние до курьера:</MediumText>
                            <TextInput
                                type={'number'}
                                value={settings?.MAX_DISTANCE_FOR_COURIER}
                                name={'MAX_DISTANCE_FOR_COURIER'}
                                onChange={change}
                            />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <MediumText>Макс. количество заказов:</MediumText>
                            <TextInput type={'number'} value={settings?.MAX_COUNT_ORDERS} name={'MAX_COUNT_ORDERS'} onChange={change} />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <MediumText>Радиус поиска курьера :</MediumText>
                            <TextInput type={'number'} value={settings?.RADIUS} name={'RADIUS'} onChange={change} />
                        </div>
                        <div className={'flex gap-3'} style={{ marginTop: '10px' }}>
                            <MediumText>Автораспределение:</MediumText>
                            <Switch
                                checked={settings?.autoDistributionEnabled}
                                onClick={() =>
                                    setSettings(pre => ({
                                        ...pre,
                                        autoDistributionEnabled: !pre.autoDistributionEnabled,
                                    }))
                                }
                            />
                        </div>
                    </div>
                )}
            </NormalModal>

            <div
                onClick={() => setIsOpen(true)}
                className={classNames(
                    'h-11 w-full flex justify-center items-center rounded-full min-w-7 min-h-10 cursor-pointer mb-4',
                    'bg-green-800',
                    'pressableSimple',
                    'backgroundTransition',
                )}>
                {isNavBarOpen ? (
                    <MediumText className='!text-white'>Автораспределение</MediumText>
                ) : (
                    <Icon type='A' fill='#FFFFFF' size={20} />
                )}
            </div>
        </>
    )
}
