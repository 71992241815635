import { atom } from 'jotai'
import { atomWithRefresh } from 'jotai/utils'
import { GetOrdersFilters, LokaliApi, Order } from 'src/services/LocaliRepository'
import { DefaultRequestState } from 'src/types'

const currentOrderAtom = atom<Order | null>(null)

const ordersFiltersAtom = atom<GetOrdersFilters | object>({})

const updateOrdersFiltersAtom = atom(null, (get, set, update: GetOrdersFilters) => {
    const filters = get(ordersFiltersAtom)
    const newFilters = { ...filters, ...update }
    set(ordersFiltersAtom, newFilters)
})

const setCurrentOrderAtom = atom(null, (_, set, update: Order) => {
    set(currentOrderAtom, update)
})

export const orderPaginationAtom = atom({
    current: 1,
    pageSize: 10,
})

const ordersListAtom = atom<Order[] | null>([])

const getOrdersAtom = atomWithRefresh<Promise<DefaultRequestState<Order[]>>>(async get => {
    try {
        const filters = get(ordersFiltersAtom)
        const data = await LokaliApi.getOrders(filters)
        return {
            isLoading: false,
            data: data,
            error: null,
        }
    } catch (e) {
        return {
            isLoading: false,
            data: null,
            error: e as Error,
        }
    }
})

export { ordersListAtom, currentOrderAtom, setCurrentOrderAtom, getOrdersAtom, ordersFiltersAtom, updateOrdersFiltersAtom }
