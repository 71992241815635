import { Route, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom'
import {
    LoginPage,
    AdminsPage,
    GroupsPage,
    MainPage,
    RestMapPage,
    RestPage,
    SMSTemplatePage,
    SettingsPage,
    AllCouriersPage,
    AllOrdersPage,
    CourierMapPage,
    ClientGeneralStatisticPage,
    ClientDatabasePage,
    ClientGroupsPage,
    ClientPushNotificationPage,
    AccountingRestPage,
    AccountingCouriersPage,
} from 'src/pages'
import { ProtectedRoute } from 'src/features/ProtectedRoute/ProtectedRoute'

import { App } from '../App'
import { UnProtectedRoute } from '../features/UnProtectedRoute/UnProtectedRoute'

import { ERoutes } from './routes'
import { Error502 } from '../pages/Error502'
import { ReportPage } from '../pages/ReportPage'

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<UnProtectedRoute />}>
                <Route path={ERoutes.LOGIN} element={<LoginPage />} />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path={ERoutes.ROOT} element={<App />}>
                    <Route path={ERoutes.REPORTS} element={<ReportPage />} />
                    <Route path={ERoutes.ACCOUNTING_REST} element={<AccountingRestPage />} />
                    <Route path={ERoutes.ACCOUNTING_COURIERS} element={<AccountingCouriersPage />} />
                    <Route path={ERoutes.ADMINS} element={<AdminsPage />} />
                    <Route path={ERoutes.CLIENT_GENERAL_STATISTICS} element={<ClientGeneralStatisticPage />} />
                    <Route path={ERoutes.CLIENTS_DATABASE} element={<ClientDatabasePage />} />
                    <Route path={ERoutes.CLIENT_GROUPS} element={<ClientGroupsPage />} />
                    <Route path={ERoutes.CLIENT_PUSH_NOTIFICATION} element={<ClientPushNotificationPage />} />
                    <Route path={ERoutes.ERROR_SERVER} element={<Error502 />} />
                    <Route path={ERoutes.GROUPS} element={<GroupsPage />} />
                    <Route path={ERoutes.ALL_KURAS} element={<AllCouriersPage />} />
                    <Route path={ERoutes.KURA_MAP} element={<CourierMapPage />} />
                    <Route path={ERoutes.MAIN_MAIN} element={<MainPage />} />
                    <Route path={ERoutes.SETTINGS_MAIN} element={<div>Settings page...</div>} />
                    <Route path={ERoutes.ALL_ORDERS} element={<AllOrdersPage />} />
                    <Route path={ERoutes.REST} element={<RestPage />} />
                    <Route path={ERoutes.REST_MAP} element={<RestMapPage />} />
                    <Route path={ERoutes.SMS_TEMPLATE} element={<SMSTemplatePage />} />
                    <Route path={ERoutes.SETTINGS} element={<SettingsPage />} />
                    <Route path='*' element={<Navigate to={ERoutes.MAIN_MAIN} replace />} />
                </Route>
            </Route>
        </>,
    ),
)
