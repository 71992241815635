import React, { useCallback, useEffect, useState } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'

import {
    Button,
    Checkbox,
    EButtonVariant,
    GridTable,
    GridTableHead,
    GridTableItem,
    MediumText,
    RegularText,
    TextInput,
} from '../../../components'
import { FinancesAccounting, LokaliApi, RestAccounting, RestAccountingOrders } from '../../../services'
import { closeDialogAtom, dialogStateAtom } from '../../../atoms/global/dialogAtom'
import { setCloseAllModals, setModalCloseAtom } from '../../../atoms/global/modalAtom'
import { currentRestAccountingAtom, setRestAccountingModalAtom } from '../../../atoms/restaraunts/restaurantAccountingAtom'

import styles from '../styles.module.scss'

import { AccountingRestDialog } from './AccountingRestDialog'
import moment from 'moment'

const THEADS = [
    'Дата',
    'Название ресторана',
    'Тип заказа',
    'Номер заказа',
    'Общая сумма',
    'Сумма доставки',
    'Сервисный сбор',
    'Суммма заказа',
    'Тип оплаты',
]

type Props = {
    restaurantId: string
    setRestAccounting: (accounting: any) => void
}

const AccountingRestModal = ({ restaurantId, setRestAccounting }: Props): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const setDialogState = useSetAtom(dialogStateAtom)
    const currentRestAccounting = useAtomValue(currentRestAccountingAtom)
    const closeDialog = useSetAtom(closeDialogAtom)
    const closeAllModals = useSetAtom(setCloseAllModals)
    const [orders, setOrders] = useState<RestAccountingOrders>()
    const [isButtonDisable, setIsButtonDisabled] = useState<boolean>(true)
    const [amount, setAmount] = useState<string>()

    const fetchOrders = useCallback(async () => {
        try {
            setLoading(true)
            const data: RestAccountingOrders = await LokaliApi.getRestAccountingOrders({ restfinance_id: restaurantId })

            setOrders(data)
        } catch (e) {
            console.warn(e)
        } finally {
            setLoading(false)
        }
    }, [])

    const pay = useCallback(async () => {
        try {
            setDialogState({
                isOpen: true,
                showCloseIcon: false,
                children: (
                    <AccountingRestDialog
                        amount={Number(amount)}
                        onAccept={pay}
                        loading
                        closeAll={closeAllModals}
                        onAbort={closeAllModals}
                        paymentSuccess={true}
                    />
                ),
            })
            const data: FinancesAccounting = await LokaliApi.payRestAccountingOrders({
                restfinance_id: restaurantId,
                sum: amount || '0',
            })

            if (data?.order_id) {
                setRestAccounting((prev: FinancesAccounting[]) =>
                    prev
                        .map(item => (item.restfinance_id === restaurantId ? data : item))
                        .sort((a, b) => (Number(a.was_paid) > Number(b.was_paid) ? 1 : -1)),
                )
                setDialogState({
                    isOpen: true,
                    showCloseIcon: false,
                    children: (
                        <AccountingRestDialog
                            amount={Number(amount)}
                            onAccept={pay}
                            loading={false}
                            closeAll={closeAllModals}
                            onAbort={closeAllModals}
                            paymentSuccess={true}
                        />
                    ),
                })
            }
        } catch (e) {
            console.warn(e)
        } finally {
        }
    }, [amount])

    const handleCloseDialog = useCallback(() => {
        closeDialog()
    }, [])

    const onPayClick = useCallback(() => {
        setDialogState({
            isOpen: true,
            children: (
                <AccountingRestDialog
                    loading={false}
                    amount={Number(amount)}
                    onAccept={pay}
                    closeAll={closeAllModals}
                    onAbort={handleCloseDialog}
                    paymentSuccess={false}
                />
            ),
        })
    }, [pay, amount])

    useEffect(() => {
        setIsButtonDisabled(!Boolean(Number(amount)))
    }, [amount])

    useEffect(() => {
        fetchOrders().then()
    }, [])

    return (
        <div>
            <div className={styles.topRow}>
                <MediumText>К оплате:</MediumText>
                <RegularText className='mr-6'>{currentRestAccounting?.totalPaymentSnapshot?.toFixed(2) || 0} руб</RegularText>
                <MediumText>К изъятию:</MediumText>
                <RegularText className='mr-6'>{currentRestAccounting?.totalSeizure} руб</RegularText>

                <MediumText>Оплатить</MediumText>
                <TextInput
                    value={amount}
                    onChange={ev => setAmount(ev.target.value)}
                    className={'w-inputM'}
                    placeholder='0.00 рублей'
                    type={'number'}
                />
                <Button disabled={isButtonDisable} loader={loading} onClick={onPayClick} variant={EButtonVariant.GREEN}>
                    <MediumText>Оплатить</MediumText>
                </Button>
            </div>

            <GridTable
                maxHeight={500}
                tableWidth='99%'
                theads={THEADS.map(head => (
                    <GridTableHead key={head} title={head} />
                ))}>
                {orders &&
                    orders.ordersArray
                        ?.sort((a, b) => b.orderNumber - a.orderNumber)
                        ?.map((orderGroup, id) => (
                            <tr key={id}>
                                <GridTableItem child={moment(orderGroup.orderDate).utc().format('DD-MM-YYYY')} className={styles.cell} />
                                <GridTableItem child={orderGroup.restName} className={styles.cell} />
                                <GridTableItem child={'-'} className={styles.cell} />
                                <GridTableItem child={orderGroup.orderNumber} className={styles.cell} />
                                <GridTableItem child={orderGroup.orderPayment} className={styles.cell} />
                                <GridTableItem child={orderGroup.deliveryPrice} className={styles.cell} />
                                <GridTableItem child={orders?.serviceFee} className={styles.cell} />
                                <GridTableItem child={orderGroup.foodCost} className={styles.cell} />
                                <GridTableItem
                                    child={orderGroup.paymentType === 'online' ? 'безналичный' : 'наличный'}
                                    className={styles.cell}
                                />
                            </tr>
                        ))}
                <GridTableItem child={'Итого'} className={styles.cell} />
                <GridTableItem child={orders?.fullOrderCount} className={styles.cell} />
                <GridTableItem child={''} className={styles.cell} />
                <GridTableItem child={''} className={styles.cell} />
                <GridTableItem child={orders?.fullPayment} className={styles.cell} />
                <GridTableItem child={orders?.fullDeliveryPrice} className={styles.cell} />
                <GridTableItem child={orders?.fullServiceFee} className={styles.cell} />
                <GridTableItem child={orders?.fullFoodCost} className={styles.cell} />
                <GridTableItem child={''} className={styles.cell} />
            </GridTable>

            <div className={styles.bottomRow}>
                <MediumText>Принести к столу:</MediumText>
                <RegularText>-</RegularText>
                <MediumText>Доставка:</MediumText>
                <RegularText>-</RegularText>
                <MediumText>Самовывоз:</MediumText>
                <RegularText>-</RegularText>
                <MediumText>Забрать на кассе:</MediumText>
                <RegularText>-</RegularText>

                <MediumText>Всего:</MediumText>
                <RegularText>{orders?.fullPayment}</RegularText>
                <MediumText>Наличными:</MediumText>
                <RegularText>{orders?.cashPayment}</RegularText>
                <MediumText>Безналичными:</MediumText>
                <RegularText>{orders?.onlinePayment}</RegularText>
            </div>
        </div>
    )
}

export default AccountingRestModal
