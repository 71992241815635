import {
    AppMap,
    Button,
    EButtonVariant,
    ErrorText,
    GridTable,
    InputWrapper,
    MediumItalic,
    RegularText,
    RoundedWrapper,
    TextInput,
} from 'src/components'
import { Circle, MapContainer, Marker, Polygon, Polyline, TileLayer, useMapEvents } from 'react-leaflet'
import classNames from 'classnames'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import {
    editCurrentDeliveryZoneAtom,
    editDeleteDeliveryZoneAtom,
    editRestDeliveryZonesAtom,
    editRestMapMarkersAtom,
    editSelectDeliveryZoneAtom,
} from 'src/atoms/restaraunts/editRestDeliveryZoneAtom'
import L, { LatLng } from 'leaflet'
import { addEditRestDeliveryZoneAtom } from 'src/atoms/restaraunts/editRestAtom'

import { currentRestAtom } from '../../atoms/restaraunts/restAtom'

import { ModalCreateDeliveryZoneFooter } from './ModalCreateDeliveryZoneFooter'
import styles from './styles.module.scss'
import GroznyiCoordinates from '../../utils/groznyiCoordinates'
import { useFormContext } from 'react-hook-form'
import { EditRestFormData } from './EditRestSchema'

const THEADS = ['Название района', 'Описание']

const circleIcon = L.divIcon({
    html: `<div style="
    width: 20px;
    height: 20px;
    background-color: #ff5722;
    border-radius: 50%;
    border: 2px solid white;">
  </div>`,
    className: 'custom-marker', // класс для кастомизации, если нужно
    iconSize: [20, 20],
    iconAnchor: [10, 10], // Центр иконки на точке
})

type tCurrentRegion = {
    id: string
    region: {
        lat: number
        lng: number
        point_id: string
    }[]
}

// @ts-ignore
const EditRestMapElements: FC<{
    currentRegion: tCurrentRegion
    setCurrentRegion: Dispatch<SetStateAction<tCurrentRegion>>
}> = ({ currentRegion, setCurrentRegion }): JSX.Element => {
    function AddMarkerOnClick() {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng
                // setMarkers(old => [...old, { lat, lng, point_id: Date.now() }])
                setCurrentRegion(pre => ({
                    ...pre,
                    region: [...pre.region, { lat, lng, point_id: Date.now().toString() }],
                }))
            },
        })
        return null
    }

    const handleDragEnd = (e, id: string) => {
        if (e.target?.getLatLng) {
            const newMarker = currentRegion.region.map(item => {
                if (id === item.point_id) {
                    const lat = e.target.getLatLng().lat
                    const lng = e.target.getLatLng().lng
                    return { lat, lng, point_id: item.point_id }
                }
                return item
            })
            setCurrentRegion(pre => ({ ...pre, region: newMarker }))
        }
    }

    return (
        <>
            <div className='absolute top-5 left-5 z-max flex gap-6'>
                <Button
                    variant={EButtonVariant.PRIMARY}
                    onClick={() => {
                        setCurrentRegion(pre => ({ ...pre, region: [] }))
                    }}>
                    <RegularText>Очистить</RegularText>
                </Button>
            </div>
            <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <AddMarkerOnClick />

            {currentRegion.region.map((position, idx) => (
                <Marker
                    key={idx}
                    position={position}
                    draggable={true}
                    icon={circleIcon}
                    eventHandlers={{
                        dragend: e => handleDragEnd(e, position.point_id),
                        click: () => {
                            const filterMap = currentRegion.region.filter((_, index) => index !== idx)
                            setCurrentRegion(pre => ({ ...pre, region: filterMap }))
                        },
                    }}
                />
            ))}

            <Polyline
                //@ts-ignore
                positions={[...currentRegion.region, currentRegion.region.length > 0 ? currentRegion.region[0] : []]}
                color='#f42d2e'
            />
        </>
    )
}

export const ModalEditRestDeliveryZone = (): JSX.Element => {
    const [deliveryZones, setDeliveryZones] = useAtom(editRestDeliveryZonesAtom)
    const [markers, setMakers] = useAtom(editRestMapMarkersAtom)
    const selectDeliveryZone = useSetAtom(editSelectDeliveryZoneAtom)
    const deleteDeliveryZone = useSetAtom(editDeleteDeliveryZoneAtom)
    const [message, setMessage] = useState(false)
    const [currentRest, setCurrentRest] = useAtom(currentRestAtom)
    const [center, setCenter] = useState(GroznyiCoordinates)

    const addDeliveryZone = useSetAtom(addEditRestDeliveryZoneAtom)

    const [currentRegion, setCurrentRegion] = useState<tCurrentRegion>({
        id: '',
        region: [],
    })

    useEffect(() => {
        const regions = currentRest?.order_region[0]
        if (Array.isArray(regions)) {
            // @ts-ignore
            setCenter(regions[0])
        }

        return () => {
            setMakers([])
            setCurrentRegion({
                id: '',
                region: [],
            })
        }
    }, [])

    return (
        <div className='flex flex-col h-full'>
            <div className='flex gap-2 h-full'>
                <div className='flex border border-solid border-mainBackgroundColor px-10 py-4'>
                    <div className='flex flex-col gap-2'>
                        <MediumItalic className='flex justify-center pt-12'>~Зоны доставки~</MediumItalic>
                        <div className='h-64 overflow-scroll'>
                            <GridTable
                                /*theads={THEADS.map(thead =>
                  <span className="bg-aqua-400 p-4" key={thead}>{thead}</span>)
                }*/
                                theads={[]}>
                                {/*@ts-ignore*/}
                                {currentRest?.order_region?.map((el, index) => (
                                    <tr>
                                        <span
                                            onClick={() => {
                                                // @ts-ignore
                                                setCurrentRegion(el)
                                            }}
                                            className={classNames(
                                                'p-4 cursor-pointer',
                                                // @ts-ignore
                                                currentRegion?.id === el.id && 'bg-darkBlue-700',
                                            )}>
                                            Зона доставки {++index}
                                        </span>
                                        {/*<span
                      onClick={()=> selectDeliveryZone(el)}
                      className={classNames(
                        'p-4 cursor-pointer',
                        currentDeliveryZone?.title === el.title && 'bg-darkBlue-700'
                      )}
                    >
                      {el.description}
                    </span>*/}
                                    </tr>
                                ))}
                            </GridTable>
                        </div>
                        <div className='flex gap-2'>
                            {currentRegion?.id?.length ? (
                                <>
                                    <Button
                                        onClick={() => {
                                            if (currentRegion.region.length > 3) {
                                                setMessage(false)
                                                // @ts-ignore
                                                setCurrentRest(pre => ({
                                                    ...pre,
                                                    order_region: currentRest?.order_region?.map(item => {
                                                        // @ts-ignore
                                                        if (item.id === currentRegion.id) {
                                                            return currentRegion
                                                        }
                                                        return item
                                                    }),
                                                }))
                                                setCurrentRegion({ id: '', region: [] })
                                            } else {
                                                setMessage(true)
                                            }
                                        }}
                                        variant={EButtonVariant.PRIMARY}>
                                        <RegularText>Сохранить изменение</RegularText>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setMakers([])
                                            setCurrentRegion({
                                                id: '',
                                                region: [],
                                            })
                                        }}
                                        variant={EButtonVariant.PRIMARY}>
                                        <RegularText>Отменить</RegularText>
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    onClick={() => {
                                        if (currentRegion.region.length > 3) {
                                            setMessage(false)

                                            // @ts-ignore
                                            setCurrentRest(pre => ({
                                                ...pre,
                                                // @ts-ignore
                                                order_region: [
                                                    // @ts-ignore
                                                    ...pre.order_region,
                                                    {
                                                        id: Date.now().toString(),
                                                        region: currentRegion.region,
                                                    },
                                                ],
                                            }))
                                            setCurrentRegion({ id: '', region: [] })
                                        } else {
                                            setMessage(true)
                                        }
                                    }}
                                    variant={EButtonVariant.PRIMARY}
                                    disabled={currentRegion.region.length < 3}
                                    icon='plus'>
                                    <RegularText>Создать зону доставки</RegularText>
                                </Button>
                            )}
                            <RoundedWrapper
                                disabled={!currentRegion.id}
                                icon='trash'
                                onClick={() => {
                                    setCurrentRegion({ id: '', region: [] })
                                    // @ts-ignore
                                    setCurrentRest(pre => ({
                                        ...pre,
                                        // @ts-ignore
                                        order_region: pre.order_region.filter(item => item.id != currentRegion.id),
                                    }))
                                }}
                            />
                        </div>
                        {message && <ErrorText error='Зона доставки должна включать больше 4 точек на карте' />}
                    </div>
                </div>
                <div className={classNames('border border-solid border-mainBackgroundColor flex-1 p-1')}>
                    {/*@ts-ignore*/}
                    <AppMap
                        //@ts-ignore
                        center={Array.isArray(currentRest?.order_region[0]) ? currentRest?.order_region[0][0] : GroznyiCoordinates}
                        mapClassname={styles.customCursor}>
                        <EditRestMapElements setCurrentRegion={setCurrentRegion} currentRegion={currentRegion} />
                    </AppMap>
                </div>
            </div>
            <ModalCreateDeliveryZoneFooter />
        </div>
    )
}
