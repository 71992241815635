import { BaseLayout, SuspenseWithSpinner } from 'src/components'
import { RestAddGroup, RestFilters, RestGroupTable, RestTable, RestTitle } from 'src/features/Restaraunts'
import { Suspense, useEffect } from 'react'
import { useAtomValue, useSetAtom } from 'jotai/index'

import { getRestMapAtom, restMapAtom } from '../../atoms/restaraunts/restMapAtom'
import { getRestAtom, restsAtom } from '../../atoms/restaraunts/restAtom'

import styles from './styles.module.scss'

export const RestPage = (): JSX.Element => (
    <div>
        <RestTitle />
        <BaseLayout
            leftChild={
                <div className={styles.leftPart}>
                    <RestAddGroup />
                    <SuspenseWithSpinner>
                        <RestGroupTable />
                    </SuspenseWithSpinner>
                </div>
            }
            filtersChild={<RestFilters />}>
            <Suspense>
                <Comp />
                <RestTable />
            </Suspense>
        </BaseLayout>
    </div>
)

const Comp = (): JSX.Element => {
    // to avoid map re-rendering inside Suspense tag
    const getRests = useAtomValue(getRestAtom)
    const setRests = useSetAtom(restsAtom)

    useEffect(() => {
        if (getRests?.data) {
            setRests(getRests.data)
        }
    }, [getRests])

    return <></>
}
