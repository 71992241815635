import { Button, GridTable, GridTableHead, GridTableItem, RegularText, SelectOption } from 'src/components'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { currentOrderAtom, getOrdersAtom, orderPaginationAtom, setCurrentOrderAtom } from 'src/atoms/allOrders/ordersAtom'
import { openCancelDialogAtom } from 'src/atoms/allOrders/cancelOrderAtom'
import { Item, Menu, useContextMenu } from 'react-contexify'
import { openOrderInfoModalAtom } from 'src/atoms/allOrders/orderInfoAtom'
import React, { FC, useEffect } from 'react'
import moment from 'moment'

import { getCourierStatusNames, getOrderStatusNames, paymentNames, statusNames } from '../../utils/statusNames'

import styles from './styles.module.scss'

const theads = [
    'Номер заказа',
    'Статус ресторана',
    'Статус курьера',
    'Статус заказа',
    'Тип заказа',
    'Дата/Время поступления заказа',
    'Сумма заказа',
    'Тип оплаты',
    'Заведение',
    'Имя клиента',
    'Контактный номер телефона клиента',
    'Причина отмены',
]

const ORDERS_CTX_ID = 'ORDERS_CTX_ID'

export const AllOrderTable: FC<{ selectedType: SelectOption }> = ({ selectedType }) => {
    const [orders, getOrders] = useAtom(getOrdersAtom)
    const { data } = orders
    const setCurrentOrder = useSetAtom(setCurrentOrderAtom)
    const currentOrder = useAtomValue(currentOrderAtom)
    const openOrderInfoModal = useSetAtom(openOrderInfoModalAtom)
    const [pagination, setPagination] = useAtom(orderPaginationAtom)

    const openCancel = useSetAtom(openCancelDialogAtom)

    const { show } = useContextMenu({
        id: ORDERS_CTX_ID,
    })

    useEffect(() => {
        if (!data?.length) return

        const orderId = window.location.search?.split('order-id=')[1]
        if (!orderId) return

        const rest = data.find(item => item.order_id === orderId)
        if (!rest) return

        setCurrentOrder(rest)
        openOrderInfoModal()
    }, [data])

    useEffect(() => {
        const timer = setInterval(() => {
            getOrders()
        }, 30000)

        return () => clearInterval(timer)
    }, [])

    return (
        <>
            {selectedType?.value === 'Закрытые заказы' && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: '16px',
                        marginTop: '8px',
                    }}>
                    <RegularText>Кол-во строк:</RegularText>
                    <select
                        className='select'
                        // value={pagination.pageSize}
                        //@ts-ignore
                        onChange={e => setPagination({ ...pagination, pageSize: e.target.value, current: 1 })}
                        style={{
                            width: '100px',
                        }}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>50</option>
                    </select>
                    <RegularText>
                        Страница: {pagination.current} -{/*{totalPages}*/}
                    </RegularText>
                    <Button
                        disabled={pagination.current === 1}
                        // onClick={handlePrevClick}
                        style={{
                            fontSize: '8px',
                        }}>
                        ◀
                    </Button>
                    <Button
                        // disabled={pagination.current >= totalPages}
                        // onClick={handleNextClick}
                        style={{
                            fontSize: '8px',
                        }}>
                        ▶
                    </Button>
                </div>
            )}
            <GridTable
                maxHeight={'calc(90vh)'}
                className={styles.table}
                // style={{ height: window.innerHeight / (window.innerWidth / 2018) - 148 + 'px' }}
                theads={theads.map(el => (
                    <GridTableHead key={el} title={el} />
                ))}>
                {data &&
                    data.map(element => {
                        const isActive = currentOrder?.order_id === element.order_id

                        return (
                            <tr
                                key={element.order_id}
                                style={{
                                    backgroundColor: element.color || '',
                                    // border: element.lining_color ? `2px solid ${element.lining_color}` : 'none',
                                }}>
                                <GridTableItem
                                    isActive={isActive}
                                    child={element.orderNumber}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={element.isDeliveryServices === true ? '-' : statusNames[element.status] || element.status}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={getCourierStatusNames(element)}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={getOrderStatusNames(element)}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={element?.isOurCouriers ? 'Locali' : 'Свои'}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={moment(element.createdAt).utc().add('3', 'hour').format('HH:mm  DD.MM.YYYY')}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={element.price}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={paymentNames[element.paymentType || ''] || element.paymentType}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={element.isDeliveryServices ? 'Независимый заказ' : element.restName || element.rest_name || '-'}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={element.clientName || element.client_name || '-'}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={element.clientPhoneNumber || element.phoneNumber || '-'}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                                <GridTableItem
                                    isActive={isActive}
                                    child={element.cancel_reason || '-'}
                                    onClick={() => setCurrentOrder(element)}
                                    onDoubleClick={openOrderInfoModal}
                                    onContextMenu={e =>
                                        show({
                                            event: e,
                                            props: {
                                                order: element,
                                            },
                                        })
                                    }
                                />
                            </tr>
                        )
                    })}
            </GridTable>
            <Menu id={ORDERS_CTX_ID}>
                <Item onClick={({ props }) => openCancel(props.order)}>Отменить заказ</Item>
            </Menu>
        </>
    )
}
